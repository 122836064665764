import {
    Grid,
    IconButton,
    makeStyles,
    Modal,
    Paper,
    Typography
  } from '@material-ui/core';
  import Papa from 'papaparse';
  import { AttachFile, Close } from '@material-ui/icons';
  import axios from 'axios';
  import React, { useCallback, useEffect, useState } from 'react';
  import BorderWrapper from 'react-border-wrapper';
  import { useDropzone } from 'react-dropzone';
  import Attachment from './attachment';
  import LoadingOverlay from 'react-loading-overlay';
  import useSettings from 'src/hooks/useSettings';
  import { API_ROOT } from 'src/config';
  
  import UploadErrors from './uploadError';
  
  //This is for data validation
  import {testSchema,profileSchema, validateData, getSchemaFromData} from './validation'
  import { storeImport } from 'src/reducers/importReducer';
  import { useDispatch, useSelector } from 'react-redux';
  
  const useStyles = makeStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  });
  
  export default function AnaFileAttachements({
    bindGuid,
    showHide,
    setShowHide,
    title,
    onComplete
  }) {
    const { settings } = useSettings();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
    const [, setParsedCsvData] = useState([]);
   
    
    const validationFields =  useSelector(state => state.imports.validationFields)
   
    useEffect(() => {
      showHide && getExistingFiles();
  
      //const results = validateData([{ username: "Alan", password: "a" },{ username: "ab", password: "abc123" }],testSchema);
      //console.log(results);
  
    }, [showHide]);
  
  
  
    const parseFile = (file,vfields) => {
      console.log(file)
      Papa.parse(file, {
        header: true,
        complete: results => {
          setParsedCsvData(results.data);
   
          const schema = getSchemaFromData(results.data,vfields);
          const result = validateData(results.data,schema);
          dispatch(storeImport({data:result, file:file}));
   
          console.log('Input Data =', result);
        },
      });
    };
  
    const onDrop = useCallback((acceptedFiles,vfiles) => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          const fileData = new FormData();
          fileData.append('formFile', file);
          fileData.append('fileName', file.name);
          fileData.append('GUIDId', bindGuid);
          fileData.append('Client', settings.project);
   
          parseFile(file,vfiles);
          try {
            const res = async () => {
              setLoading(true);
              await axios.post(API_ROOT + 'File/SendFile', fileData);
              await getExistingFiles().then(() => setLoading(false));
            };
            res();
            onComplete && setTimeout(onComplete, 1000);
          } catch (err) {
            console.log(err);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    }, []);
  
    const deleteFile = async fileName => {
      await axios.get(
        API_ROOT +
          'File/DeleteFile/' +
          settings.project +
          '/' +
          bindGuid +
          '/' +
          fileName
      );
      await getExistingFiles();
      onComplete && onComplete();
    };
  
    const getExistingFiles = async () => {
      await axios
        .get(API_ROOT + 'File/FileList/' + settings.project + '/' + bindGuid)
        .then(result => {
          setFiles(result.data);
          return result.data;
        });
    };
  
    const { getRootProps, getInputProps,isDragActive } = useDropzone({ onDrop: (params)=> onDrop(params,validationFields) });
  
    return (
      
        <Paper >
            
          <LoadingOverlay
            active={loading}
            text="Uploading files, please wait.."
            spinner
          >
            <Grid container>
        
              <Grid item xs={12} style={{padding:10}}>
                <Typography fontSize={14} style={{ fontWeight: 600 }}>
                  {title ? title : 'Attachments'}
                </Typography>
              </Grid>
     
              <Grid item xs={12}>
                <div {...getRootProps({className:'dropzone'})} style={{height:300,cursor:'pointer',textAlign:'center', verticalAlign:'middle', borderRadius:5, padding:100, margin:10, backgroundColor:'#f0f0f0'}}>
                 
                    <input {...getInputProps()}   />
                    <Typography variant="h3" style={{textAlign:'center', verticalAlign:'middle'}}>
                      Drag &amp; drop files here, or click to add files.
                    </Typography>
                  
                </div>
                {showHide &&
                  files.map(a => (
                    <Attachment
                      fileName={a}
                      downloadLink={
                        API_ROOT +
                        'File/getFile/' +
                        settings.project +
                        '/' +
                        bindGuid +
                        '/' +
                        a
                      }
                      deleteRequest={deleteFile}
                    />
                  ))}
              </Grid>
            </Grid>{' '}
          </LoadingOverlay>{' '}
        </Paper>
     
    );
  }
  