/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { List, ListSubheader } from '@material-ui/core';
import { matchPath, useLocation } from 'react-router';
import HasRole from 'src/views/auth/HasRole';
import NavItem from './NavItem';

function renderNavItems({ items }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item, index) => reduceChildRoutes({ acc, item }, index),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc, pathname, item, depth = 0
}, index) {
  const key = item.title + index;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      item.role ? (
        <div key={key}>
          <HasRole requiredRole={item.role}>
            <NavItem
              depth={depth}
              icon={item.icon}
              info={item.info}
              open={Boolean(open)}
              title={item.title}
            >
              {renderNavItems({
                depth: depth + 1,
                pathname,
                items: item.items
              })}
            </NavItem>
          </HasRole>
        </div>
      ) : (
        <NavItem
          depth={depth}
          icon={item.icon}
          key={key}
          info={item.info}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: index + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      )
    );
  } else {
    acc.push(
      item.role ? (
        <div key={key}>
          <HasRole requiredRole={item.role}>
            <NavItem
              depth={depth}
              href={item.href}
              icon={item.icon}
              info={item.info}
              title={item.title}
            />
          </HasRole>
        </div>
      ) : (
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={key}
          info={item.info}
          title={item.title}
        />
      )
    );
  }

  return acc;
}

const ListForNav = ({ config }) => {
  const location = useLocation();

  return (
    <>
      {config.role ? (
        <div key={`first-list-${config.id}`}>
          <HasRole requiredRole={config.role}>
            <List
              subheader={(
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          </HasRole>
        </div>
      ) : (
        <div key={`second-list-${config.id}`}>
          <List
            subheader={(
              <ListSubheader disableGutters disableSticky>
                {config.subheader}
              </ListSubheader>
            )}
          >
            {renderNavItems({
              items: config.items,
              pathname: location.pathname
            })}
          </List>
        </div>
      )}
    </>
  );
};

ListForNav.propTypes = {
  config: PropTypes.shape()
};

export default ListForNav;
