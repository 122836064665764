import React, { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import {
  gridPageSelector,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
  gridPaginatedVisibleSortedGridRowEntriesSelector
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { setDisplayItems } from 'src/reducers/mapLayerReducer';
import { useDispatch } from 'react-redux';

const NoMapFooter = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rows = useGridSelector(
    apiRef,
    gridPaginatedVisibleSortedGridRowEntriesSelector
  );

  const dispatch = useDispatch();

  useEffect(() => {
    let finalRows = [];
    rows.map((row) => finalRows.push(row.model));
    dispatch(setDisplayItems({ rows: finalRows }));
    finalRows = [];
  }, [page, pageCount, dispatch, rows]);

  return (
    <Box>
      <Pagination
        sx={{
          float: 'right',
          paddingBottom: 2
        }}
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Box>
  );
};

export default NoMapFooter;
