// Basic component to put a border around a selected component
// Developed to help highlighted search results
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

const SelectedComponent = ({ selected, children }) => {
  const theme = useTheme();

  return (
    <>
      {selected ? (
        <div
          style={{
            border: `1px solid ${theme.palette.primary.main}`,
            padding: '5px',
          }}
        >
          <div style={{ clear: 'both' }} />
          {children}
        </div>
      ) : (
        <>{children}</>
      )}

    </>
  );
};

export default SelectedComponent;

SelectedComponent.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.node
};
