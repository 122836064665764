import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataGridPro, GridToolbar, useGridApiRef } from '@mui/x-data-grid-pro';
import useSettings from 'src/hooks/useSettings';
import { hasRole } from 'src/lib';
import { useKeycloak } from '@react-keycloak/web';
import { setSelectedItem } from 'src/reducers/mapLayerReducer';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import {
  Card, CardContent, CardHeader, IconButton
} from '@material-ui/core';
import { ExitToApp, PieChart } from '@material-ui/icons';
import ReactCardFlip from 'react-card-flip';
import NoMapFooter from './NoMapFooter';
import PieDataChart from '../PieDataChart';
import MapPageFooter from './MapPageFooter';

const DataGridMapInteraction = ({
  columnData,
  name,
  dataGridOptions,
  data,
  chartOptions,
  hideMap
}) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState(dataGridOptions?.filterModel);
  const [view, setView] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const { settings } = useSettings();
  const { keycloak } = useKeycloak();
  const mapSelectedItem = useSelector((state) => state.mapConfig.selectedItem);
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (data) {
      const updatedColumns = [];
      const sortedColummns = [];
      columnData.map((column) => {
        if (column.requiresRole) {
          const result = hasRole(
            keycloak,
            settings.project,
            column.requiresRole
          );
          if (!result) {
            return {};
          }
          return updatedColumns.push(column);
        }
        if (column.sort) {
          sortedColummns.push({
            field: column.field,
            sort: column.sort
          });
        }
        return updatedColumns.push(column);
      });

      setSortModel(sortedColummns);
      setColumns(updatedColumns);
      setRows(data);
      setFilterModel(dataGridOptions.filterModel);
      setLoading(false);
    }
  }, [
    data,
    columnData,
    settings.project,
    dataGridOptions.filterModel,
    keycloak
  ]);

  return (
    <ReactCardFlip isFlipped={showChart}>
      {/* FRONT_COMPONENT */}
      <Card style={{ width: '100%' }}>
        <CardHeader
          action={(
            <IconButton
              aria-label="View Chart"
              color="primary"
              onClick={() => setShowChart(!showChart)}
            >
              <PieChart titleAccess="View Chart" />
            </IconButton>
          )}
          title={`${name.toUpperCase()} (${rows?.length || 0})`}
          titleTypographyProps={{ variant: 'h4' }}
        />
        <CardContent>
          <Box
            sx={{
              '& .cold': {
                backgroundColor: '#b9d5ff91',
                color: '#1a3e72'
              },
              height: hideMap ? '52vh' : '85vh'
            }}
          >
            <DataGridPro
              sx={{
                color: 'inherit',
                '& .MuiDataGrid-toolbarContainer': {
                  button: {
                    color:
                      settings.theme === 'LIGHT' ? 'primary.main' : '#e6e5e8'
                  }
                },
                '& .MuiToolbar-root': {
                  color:
                    settings.theme === 'LIGHT' ? 'primary.main' : '#e6e5e8',
                  button: {
                    color:
                      settings.theme === 'LIGHT' ? 'primary.main' : '#e6e5e8'
                  }
                },
                button: {
                  color: settings.theme === 'LIGHT' ? 'text.primary' : '#e6e5e8'
                }
              }}
              apiRef={apiRef}
              aria-label={name}
              loading={loading}
              components={{
                Toolbar: GridToolbar,
                Footer: hideMap ? NoMapFooter : MapPageFooter
              }}
              rows={rows}
              columns={columns}
              pagination
              page={view}
              autoPageSize
              sortModel={sortModel}
              filterModel={filterModel}
              onFilterModelChange={(model) => setFilterModel(model)}
              onSortModelChange={(model) => setSortModel(model)}
              onPageChange={(page) => setView(page)}
              onRowClick={(params) => dispatch(setSelectedItem(params.row))}
              getRowClassName={(params) => {
                if (mapSelectedItem?.id === params.id) {
                  return `cold ${params.row}`;
                }
              }}
              disableSelectionOnClick
              disableColumnMenu
              {...dataGridOptions?.props}
            />
          </Box>
        </CardContent>
      </Card>
      {/* BACK_COMPONENT */}
      <Card>
        <CardHeader
          title={`${name.toUpperCase()} (${rows?.length || 0})`}
          titleTypographyProps={{ variant: 'h4' }}
          action={(
            <IconButton
              aria-label="Exit Chart"
              color="primary"
              onClick={() => setShowChart(!showChart)}
            >
              <ExitToApp titleAccess="Exit Chart" />
            </IconButton>
          )}
        />
        <CardContent>
          {showChart ? (
            <PieDataChart data={rows} chartOptions={chartOptions} />
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </ReactCardFlip>
  );
};

DataGridMapInteraction.propTypes = {
  noCardCanvas: PropTypes.bool,
  hideMap: PropTypes.bool,
  columnData: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  dataGridOptions: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  chartOptions: PropTypes.object
};

export default DataGridMapInteraction;
