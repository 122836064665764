import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  url: 'https://identity.esassoc.net:8443/auth',
  realm: 'ESA-BFD',
  clientId: 'lebls',
  sslRequired: 'external',
  publicClient: true,
  clientSecet: 'ae3401fa-73fc-493b-8ebf-43f3822a1dda',
  confidentialPort: 0,
  redirect_uri: window.location.href,
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
});

export default keycloak;

// tokenKey: 'kc-token',
// refreshTokenKey: 'kc-refresh',
