import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import kanbanReducer from './kanbanReducer';
import MapConfig from './mapLayerReducer';
import reportReducer from './reportReducer';
import dashboardReducer from './dashboardReducer';
import  ProjectSettings  from './projectSettingsReducer';
import  importsReducer  from './importReducer';
const rootReducer = combineReducers({
  account: accountReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  mail: mailReducer,
  kanban: kanbanReducer,
  form: formReducer,
  mapConfig: MapConfig,
  dashboardData: dashboardReducer,
  reports: reportReducer,
  projectSettings : ProjectSettings,
  imports : importsReducer
});

export default rootReducer;
