import { enableES5 } from 'immer';
import 'nprogress/nprogress.css';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import { Provider } from 'react-redux';
import 'src/assets/css/prism.css';
import { SettingsProvider } from 'src/context/SettingsContext';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
import 'src/mock';
import * as serviceWorker from 'src/serviceWorker';
import { configureStore } from 'src/store';
import { restoreSettings } from 'src/utils/settings';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import LoadingScreen from 'src/components/LoadingScreen';
import { LicenseInfo } from '@mui/x-license-pro';
import keycloak from './keycloak';
import App from './App';
import { MATERIAL_GRID_LIC } from './config';

LicenseInfo.setLicenseKey(
  MATERIAL_GRID_LIC
);

enableES5();

const store = configureStore();
const settings = restoreSettings();

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      onLoad: 'login-required',
      checkLoginIframe: false
    }}
    LoadingComponent={<LoadingScreen />}
  >
    <Provider store={store}>
      <SettingsProvider settings={settings}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </SettingsProvider>
    </Provider>
  </ReactKeycloakProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
