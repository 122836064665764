import {
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Typography
} from '@material-ui/core';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { AttachFile, Close } from '@material-ui/icons';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import BorderWrapper from 'react-border-wrapper';
import { useDropzone } from 'react-dropzone';
import LoadingOverlay from 'react-loading-overlay';
import useSettings from 'src/hooks/useSettings';
import { API_ROOT } from 'src/config';

import { storeImport } from 'src/reducers/importReducer';
import { useDispatch, useSelector } from 'react-redux';

// This is for data validation
import { validateData, getSchemaFromData } from './validation';
import Attachment from './attachment';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default function Attachments({
  bindGuid,
  showHide,
  setShowHide,
  title,
  onComplete
}) {
  const { settings } = useSettings();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const [, setParsedCsvData] = useState([]);

  const validationFields = useSelector((state) => state.imports.validationFields);

  const getExistingFiles = async () => {
    await axios
      .get(`${API_ROOT}File/FileList/${settings.project}/${bindGuid}`)
      .then((result) => {
        setFiles(result.data);
        return result.data;
      });
  };

  useEffect(() => {
    if (showHide) {
      getExistingFiles();
    }
  }, [showHide]);

  const parseFile = (file, vfields) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setParsedCsvData(results.data);

        const schema = getSchemaFromData(results.data, vfields);
        const result = validateData(results.data, schema);
        dispatch(storeImport({ data: result, file }));
      }
    });
  };

  const onDrop = useCallback((acceptedFiles, vfiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const fileData = new FormData();
        fileData.append('formFile', file);
        fileData.append('fileName', file.name);
        fileData.append('GUIDId', bindGuid);
        fileData.append('Client', settings.project);

        parseFile(file, vfiles);
        try {
          const res = async () => {
            setLoading(true);
            await axios.post(`${API_ROOT}File/SendFile`, fileData);
            await getExistingFiles().then(() => setLoading(false));
          };
          res();
          if (onComplete) {
            setTimeout(onComplete, 1000);
          }
        } catch (err) {
          console.log(err);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const deleteFile = async (fileName) => {
    await axios.get(
      `${API_ROOT}File/DeleteFile/${settings.project}/${bindGuid}/${fileName}`
    );
    await getExistingFiles();
    if (onComplete) {
      onComplete();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (params) => onDrop(params, validationFields)
  });

  return (
    <Modal open={showHide || false} className={classes.modal}>
      <Paper style={{ padding: 10, borderRadius: 10, textAlign: 'center' }}>
        <LoadingOverlay
          active={loading}
          text="Uploading files, please wait.."
          spinner
        >
          <Grid container>
            <Grid item xs={1}>
              <AttachFile />
            </Grid>
            <Grid item xs={9}>
              <Typography fontSize={14} style={{ fontWeight: 600 }}>
                {title || 'Attachments'}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              <IconButton size="small" onClick={() => setShowHide(false)}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Paper {...getRootProps()}>
                <BorderWrapper
                  borderColour="#B5B6B8"
                  topOffset="35px"
                  topGap="4px"
                  topPosition={0.05}
                >
                  <>
                    <input {...getInputProps()} height={300} />
                    <Typography variant="subtitle2">
                      Drag &amp; drop files here, or click to add files.
                    </Typography>
                  </>
                </BorderWrapper>
              </Paper>
              {showHide
                && files.map((file) => (
                  <Attachment
                    fileName={file}
                    downloadLink={`${API_ROOT}File/getFile/${settings.project}/${bindGuid}/${file}`}
                    deleteRequest={deleteFile}
                  />
                ))}
            </Grid>
          </Grid>
          {' '}
        </LoadingOverlay>
        {' '}
      </Paper>
    </Modal>
  );
}

Attachments.propTypes = {
  bindGuid: PropTypes.string,
  setShowHide: PropTypes.func,
  showHide: PropTypes.any,
  title: PropTypes.string,
  onComplete: PropTypes.any
};
