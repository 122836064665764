
import axios from 'axios';
import { API_ROOT } from 'src/config';
export const getLayers = async (settings) => {
    let layers = [];
    let result = await axios.get(
      API_ROOT + 'File/FileList/' + settings.project + '/MAP-FILES'
    );
    // console.log('Map layers ==> ',result.data)
    for (let file of result.data) {
      //  console.log(file)
      let fileJson = await axios.get(
        API_ROOT + 'File/getFile/' + settings.project + '/MAP-FILES/' + file
      );
      ///TODO: Fix this. Array object locked - use strict or whatever is blocking additions to array.
      //Weird stuff going on here.

      layers.push({ file: file, data: fileJson.data });
      
      layers = [...layers];
    }

    return layers;
  };