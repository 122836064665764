/* eslint-disable no-debugger */
import MomentUtils from '@date-io/moment';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import rtl from 'jss-rtl';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSettings from 'src/hooks/useSettings';
import ScrollReset from 'src/components/ScrollReset';
import { BrowserRouter } from 'react-router-dom';
import { initializeTheme } from 'src/theme';
import axios from 'axios';
import { API_ROOT } from 'src/config';
import { SnackbarProvider } from 'notistack';
import { API_BASE_URL } from './config';
import { setLayers } from './reducers/mapLayerReducer';
import { setSettings } from './reducers/projectSettingsReducer';
import Routes from './Routes';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

function App() {
  useStyles();
  const { settings } = useSettings();

  const dispatch = useDispatch();

  useEffect(() => {
    const getSettings = async (project) => {
      const url = `${API_BASE_URL}query`;
      const qry = `SELECT * from projectSettings where project='${project}'`;
      const result = await axios.post(url, { query: qry });
      const settings = {};
      if (result.data.rows.length > 0) {
        result.data.rows.forEach((row) => {
          let setTemp = row.settingString !== '' ? row.settingString : row.settingNumeric;
          if (row.isJSON === 'True') {
            setTemp = JSON.parse(setTemp);
          }
          settings[row.settingName] = setTemp;
        });

        dispatch(setSettings(settings));
      } else {
        console.error('Developer Error: This project has no project settings');
      }
    };

    const getLayers = async (project) => {
      let layers = [];
      const result = await axios.get(
        `${API_ROOT}File/FileList/${project}/MAP-FILES`
      );
      for (const file of result.data) {
        const fileJson = await axios.get(
          `${API_ROOT}File/getFile/${project}/MAP-FILES/${file}`
        );
        /// TODO: Fix this. Array object locked - use strict or whatever is blocking additions to array.
        // Weird stuff going on here.
        layers.push({ file, data: fileJson.data });
        layers = [...layers];
      }
      dispatch(setLayers(layers));
    };

    getLayers(settings.project).then(() => {
      console.log('Got layers');
    });

    getSettings(settings.project).then(() => {
      console.log('Got settings');
    });
  }, [dispatch, settings]);

  return (
    <ThemeProvider theme={initializeTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <BrowserRouter>
              <ScrollReset />
              <Routes />
            </BrowserRouter>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
