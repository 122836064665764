import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import axios from 'axios';

// Icon Button for downloading daily report
export default function InteractiveIconButton({ report }) {
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);
      axios
        .request({
          url: report.value,
          method: 'GET',
          responseType: 'blob'
        })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute(
            'download',
            `DMR_${report.row.field_person}_${moment(
              report.row.date_general
            ).format('MMMM, DD YYYY')}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <LoadingOverlay active={loading} spinner>
        <IconButton
          aria-label="download pdf"
          color="primary"
          disabled={loading}
          onClick={handleButtonClick}
        >
          <PictureAsPdf fontSize="small" color="primary" />
        </IconButton>
      </LoadingOverlay>
    </div>
  );
}

InteractiveIconButton.propTypes = {
  report: PropTypes.object.isRequired
};
