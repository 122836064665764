import React, { useState } from 'react';
import {
  Paper, Grid, Checkbox, Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setLayerVis } from 'src/reducers/mapLayerReducer';
import PropTypes from 'prop-types';

const LayerControlItem = ({ item }) => {
  const dispatch = useDispatch();
  const [, forceRerender] = useState();

  const handleHideShow = () => {
    // dispatch back to redux
    dispatch(setLayerVis(item));
    const rand = Math.random(10000);

    forceRerender(rand);
  };
  return (
    <Grid item xs={12}>
      <Paper
        style={{ maxheight: 10, padding: 10, cursor: 'pointer' }}
        elevation={item?.visible === undefined || item?.visible === true ? 5 : 1}
        color={
          item?.visible === undefined || item?.visible === true
            ? 'primary'
            : '#ffffff'
        }
        onClick={handleHideShow}
      >
        <Grid container>
          <Grid
            item
            xs={11}
            style={{ textAlign: 'left', verticalAlign: 'center' }}
            align="center"
          >
            <Typography>{item.file.replace('.geojson', '')}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Checkbox
              checked={item.visible === undefined || item.visible === true}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

LayerControlItem.propTypes = {
  item: PropTypes.shape({
    visible: PropTypes.bool,
    file: PropTypes.string,
  })
};

export default LayerControlItem;
