


import { ANA_API } from "src/config"
import axios from "axios"
import Client from "esa-gcclient";

const padDigit = (digit)=>{
    if(digit.toString().length===1)
        return `0${digit}`;
    return `${digit}`
}
export const LatestImports = async ()=>{
    ///const instance = axios.create();
    let uri=  ANA_API + 'RecordSources';
    //console.log('Getting latest imports from ',uri)
    let resp = await axios.get(uri);
    //console.log('Response from Imports==>',resp)
    return resp.data;
 

}
const getStation =  (stationId)=>{
    Client.getData('station',`stationid=${stationId}`).then(station=> {
        console.log('returning', station)
        return station});
}

export const createDeploymentRef =    (data, prefix)=>{
   
    if(!data.stationName)
        return "NO-REF-NO-STATION-NAME"
    //do we have stationid or stationName ??
    let stationName = data.stationName

 /*    if(data.stationid && !data.stationName){
        console.log(data)
    
        let station =  getStation(data.stationid)
        console.log('station', station)
      stationName = station.data.length > 0 ? station.data[0].stationName : stationName;
    } */
    //Datetime field 
    let datetime = data.datetime? data.datetime : data.startTime;
    
    try{
        
        
        let dTemp = new Date(datetime);
        
        let ref = `${prefix}${padDigit(dTemp.getFullYear()-2000)}${padDigit(dTemp.getUTCMonth()+1)}${padDigit(dTemp.getDate())}${stationName}`;
      //  console.log('ref',ref)
        return ref 

    }catch(err){
        console.log('Could not parse date correctly',err)
        return "";
    }
   

     
}