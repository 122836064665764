import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import {
  ArrowDownward as ArrowDown,
  ArrowUpward as ArrowUp,
  DateRange
} from '@material-ui/icons';
import Client from 'esa-gcclient';
import { getPercentile } from 'esa-gcclient/utils';
import { TimeRange, TimeSeries } from 'pondjs';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  Baseline,
  ChartContainer,
  ChartRow,
  Charts,
  EventMarker,
  LineChart,
  Resizable,
  styler,
  YAxis
} from 'react-timeseries-charts';
import SimpleMetric from 'src/views/reports/DashboardView/SimpleMetric';

const st = styler([
  { key: 'Bottom', color: '#2ca02c', width: 2 },

  { key: 'Floating', color: '#ff0000', width: 2 }
]);
const percentiles = [60, 99];

const ContStation = ({ stationName }) => {
  // FIX: page doesn't render when clicked
  const [metrics, setMetrics] = useState([]);
  const [track, setTrack] = useState({});
  const [startDate, setStartDate] = useState('4-1-2018');
  const [endDate, setEndDate] = useState('4-2-2018');
  const [markerMode] = useState('flag');
  const [dateDiag, setDateDiag] = useState(false);
  const [tr, setTr] = useState();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection'
  });
  const [tracker, setTracker] = useState();

  const [chartSeries, setChartSeries] = useState();
  const [positions, setPositions] = useState([]);

  const getTSChart = useCallback((data) => {
    const series = [];
    data.forEach((metric) => {
      const points = [];

      metric.records.sort(
        (a, b) => new Date(a.dataDateTime) - new Date(b.dataDateTime)
      );
      metric.records.forEach((item) => {
        const dt = new Date(item.dataDateTime);
        const value = item.dataValue === -9999 ? undefined : item.dataValue;
        let pushed = false;
        points.forEach((pnt) => {
          if (pnt[0] instanceof Date && pnt[0].getTime() === dt.getTime()) {
            pushed = true;
            pnt[positions.indexOf(item.crPositionName) + 1] = value;
          }
        });
        if (pushed === false) {
          const arrItem = [positions.length];
          arrItem[0] = dt;
          arrItem[positions.indexOf(item.crPositionName) + 1] = value;
          points.push(arrItem);
        }
      });
      const _ts = new TimeSeries({
        name: metric.metricName,
        columns: ['time', ...positions],
        points
      });
      series.push(_ts);
    });

    setChartSeries(series);
  }, [positions]);

  useEffect(() => {
    Client.getData(
      'continuousData',
      `stationName='${stationName}' AND dataDateTime < '${endDate}' and dataDateTime > '${startDate}' `,
      false
    ).then((result) => {
      const _metrics = [];
      result.data.sort((a, b) => new Date(a.tStamp) - new Date(b.tStamp));
      result.data.forEach((a) => {
        const res = _metrics.find((met) => met.metricName === a.metricName);
        if (positions.indexOf(a.crPositionName) === -1) {
          const posNew = positions;
          posNew.push(a.crPositionName);
          setPositions(posNew);
        }
        if (!res) {
          _metrics.push({
            metricName: a.metricName,
            unitName: a.unitName,
            records: [a]
          });
        } else {
          res.records.push(a);
        }
      });

      _metrics.forEach ((a) => {
        percentiles.forEach ((perc) => {
          const p = perc.toFixed(2) / 100;
          a[`perc${perc}`] = getPercentile(a.records, 'dataValue', p);
        });
      });
      const start = new Date(startDate);
      const end = new Date(endDate);

      setMetrics(_metrics);
      setTr(new TimeRange(start, end));
      getTSChart(_metrics);
    });
  }, [stationName, startDate, endDate, positions, getTSChart]);

  const NullMarker = () => <g />;
  const renderMarker = (axis, ndx) => {
    if (!track.tracker || track.trackerNdx !== ndx) {
      return <NullMarker />;
    }
    if (markerMode === 'flag') {
      return (
        <EventMarker
          infoStyle={{ fill: 'red', opacity: 1 }}
          type="flag"
          axis={axis}
          event={track.trackerEvent}
          column="Bottom"
          info={[{ label: metrics[ndx].metricName, value: track.trackerValue }]}
          infoTimeFormat="%m/%d/%Y %H:%M"
          infoWidth={120}
          markerRadius={2}
          markerStyle={{ fill: 'red' }}
        />
      );
    }
    return (
      <EventMarker
        infoStyle={{ color: '#000000', fill: '#000000', opacity: 1 }}
        type="point"
        axis={axis}
        event={track.trackerEvent}
        column="Bottom"
        markerLabel={track.trackerValue}
        markerLabelAlign="left"
        markerLabelStyle={{ fill: '#ffffff', stroke: 'white' }}
        markerRadius={3}
        markerStyle={{ fill: '#ffffff' }}
      />
    );
  };
  const handleTrackerChanged = (t, ndx) => {
    setTracker(t);
    if (t) {
      const e = chartSeries[ndx].atTime(t);

      const eventTime = new Date(
        e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
      );
      const eventValue = e.get('Bottom');
      const v = `${eventValue > 0 ? '+' : ''}${eventValue} ${
        metrics[ndx].unitName
      }`;
      setTrack({
        tracker: eventTime,
        trackerValue: v,
        trackerEvent: e,
        trackerNdx: ndx
      });
    } else {
      setTrack({
        tracker: null,
        trackerValue: null,
        trackerEvent: null,
        trackerNdx: null
      });
    }
  };
  const handleRangeChange = (ranges) => {
    if (ranges.selection.endDate > ranges.selection.startDate) {
      if (ranges.selection.endDate !== ranges.selection.startDate) {
        setEndDate(ranges.selection.endDate.toLocaleDateString());
        setStartDate(ranges.selection.startDate.toLocaleDateString());
        setDateRange(ranges.selection);
      }
    }
  };

  const tsChart = (series) => {
    if (!metrics || metrics.length === 0) return 'No data';
    return (
      <>
        {series.forEach ((seriItem, ndx) => (
          <Resizable>
            <ChartContainer
              trackerPosition={tracker}
              showGridPosition="over"
              enableDragZoom
              style={{ flex: 1, width: '100%' }}
              timeRange={tr}
              onTimeRangeChanged={(timeRange) => setTr(timeRange)}
              onTrackerChanged={(e) => handleTrackerChanged(e, ndx)}
            >
              <ChartRow height={250} style={{ backgroundColor: '#0000ff' }}>
                <YAxis
                  id={`${metrics[ndx].metricName}_axis1`}
                  label={metrics[ndx].metricName}
                  type="linear"
                  format=".2f"
                  max={seriItem.max(positions[0])}
                  min={seriItem.min(positions[0])}
                />
                <Charts>
                  <LineChart
                    style={st}
                    axis={`${metrics[ndx].metricName}_axis1`}
                    series={seriItem}
                    columns={positions}
                    max={seriItem.max(positions[0])}
                    min={seriItem.min(positions[0])}
                  />

                  {percentiles.forEach ((a) => (
                    <Baseline
                      value={metrics[ndx][`perc${a}`]}
                      axis={`${metrics[ndx].metricName}_axis1`}
                      label={`${a}th Percentile`}
                    />
                  ))}

                  {renderMarker(`${metrics[ndx].metricName}_axis1`, ndx)}
                </Charts>
              </ChartRow>
            </ChartContainer>
          </Resizable>
        ))}
      </>
    );
  };

  return (
    <Paper>
      <Grid container spacing={5} style={{ flex: 1 }}>
        <Grid item md={12}>
          <Typography variant="h3">
            {' '}
            {stationName}
            {' '}
          </Typography>
          <Button onClick={() => setDateDiag(true)}>Select Dates</Button>
          <Dialog open={dateDiag}>
            <DialogTitle onClick={() => setDateDiag(false)}>
              Select Date Range
            </DialogTitle>

            <DateRangePicker
              ranges={[dateRange]}
              onChange={handleRangeChange}
            />
          </Dialog>
        </Grid>
        <Grid item lg={12}>
          <Grid container>
            {metrics.forEach ((item, ndx) => (
              <Grid lg={2}>
                <SimpleMetric
                  value={
                    chartSeries[ndx]
                    && `${chartSeries[ndx].crop(tr).max('Bottom')} ${
                      item.unitName
                    }`
                  }
                  title={`${item.metricName} max`}
                  icon={<ArrowUp />}
                />
                <SimpleMetric
                  value={
                    chartSeries[ndx]
                    && `${chartSeries[ndx].crop(tr).min('Bottom')} ${
                      item.unitName
                    }`
                  }
                  title={`${item.metricName} min`}
                  icon={<ArrowDown />}
                />
              </Grid>
            ))}
            <Grid lg={2}>
              <SimpleMetric
                title="Start Date"
                icon={<DateRange />}
                value={tr?._range._tail.array[0].toLocaleDateString()}
              />
              <SimpleMetric
                title="End Date"
                icon={<DateRange />}
                value={tr?._range._tail.array[1].toLocaleDateString()}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} xl={12}>
          <Paper elevation={10} style={{ flex: 1, width: '90%' }}>
            {tsChart(chartSeries)}
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ContStation;

ContStation.propTypes = {
  stationName: PropTypes.string
};
