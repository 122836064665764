import React from 'react';
import { useParams } from 'react-router';
import ContStation from './station';

export default function Continuous() {
  const para = useParams();

  return (
    <>
      <ContStation stationName={para.item} />
    </>
  );
}
