import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Map, { Source, Layer } from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux';
import ObservationMarker from 'src/views/map/ObservationMarker';
import AdditionalObservationMarker from './AdditonalObservationMarker';
import Box from '@mui/material/Box';
import { setSelectedItem } from 'src/reducers/mapLayerReducer';
import LayerControl from './LayerControl';
import { v4 as uuidv4 } from 'uuid';

const TOKEN =
  'pk.eyJ1IjoiZXNhZ2lzIiwiYSI6ImV3dnkyWjAifQ.SYBjFR-Bw0YDntotGjtZxQ';

const MapContainer = ({ mapHeight }) => {
  const [data, setData] = useState();
  const projectSettings = useSelector(
    state => state.projectSettings.settings.mapSettings
  );
  const mapItems = useSelector(state => state.mapConfig.displayItems);
  const selectedItem = useSelector(state => state.mapConfig.selectedItem);
  const report = useSelector(state => state.reports);
  const observationReport = useSelector(state => state.reports.observationLogs);
  const mapLayer = useSelector(state => state.mapConfig);
  const dispatch = useDispatch();
  const mapRef = useRef();
  const location = useLocation();

  const onMove = useCallback(
    (lng, lat) =>
      mapRef.current?.flyTo({
        center: [lng, lat],
        duration: 2000,
        zoom: projectSettings.zoom * 1.5,
        essential: true
      }),
    [projectSettings.zoom]
  );

  const onResize = useCallback(
    mapHeight => mapRef.current?.resize({ height: mapHeight }),
    []
  );

  useEffect(() => {
    const geoJson = {
      type: 'FeatureCollection',
      features: []
    };

    if (location.pathname.includes('DailyReports')) {
      const feature = {
        type: 'Feature',
        id: report.dailyReport.metaData.id,
        properties: {
          ...report.dailyReport
        }
      };
      geoJson.features.push(feature);
      setData(geoJson);
    }

    if (location.pathname.includes('Observation')) {
      const feature = {
        type: 'Feature',
        id: observationReport?.metaData?.id,
        geometry: {
          ...observationReport?.metaData?.geometry
        },
        properties: {
          ...observationReport
        }
      };
      geoJson.features.push(feature);
      setData(geoJson);
    }

    if (location.pathname.includes('dashboard')) {
      if (mapItems.rows || mapItems.rows?.length > 0) {
        mapItems.rows.forEach(item => {
          const feature = {
            type: 'Feature',
            id: item.id,
            geometry: {
              ...item._geometry
            },
            properties: {
              ...item
            }
          };
          geoJson.features.push(feature);
        });
      }
      setData(geoJson);
    }

    if (
      selectedItem?._geometry?.coordinates[0] !==
      mapRef?._geometry?.coordinates[0]
    ) {
      const lat = parseFloat(selectedItem._geometry?.coordinates[1]);
      const lng = parseFloat(selectedItem._geometry?.coordinates[0]);
      onMove(lng, lat);
    }

    if (mapHeight) {
      onResize(mapHeight);
    }
  }, [
    mapItems,
    projectSettings,
    selectedItem,
    mapRef,
    onMove,
    mapHeight,
    onResize,
    report,
    location.pathname,
    observationReport
  ]);

  const onSelectCity = useCallback(
    ({ lng, lat }, obj) => {
      if (!location.pathname.includes('dashboard')) {
        return;
      }
      dispatch(setSelectedItem(obj));
      return mapRef.current?.flyTo({
        center: [lng, lat],
        duration: 2000,
        zoom: projectSettings.zoom * 1.5,
        essential: true
      });
    },
    [projectSettings.zoom, dispatch, location.pathname]
  );

  const dataLayer = {
    id: 'customLayer',
    source: 'customLayer',
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#ffb74d',
      'fill-opacity': 0.5,
      'fill-outline-color': '#ff9800'
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <LayerControl />
      <Box
        sx={{
          zIndex: 1,
          overflow: 'hidden'
        }}
      >
        <Map
          ref={mapRef}
          initialViewState={{
            longitude: projectSettings.lng,
            latitude: projectSettings.lat,
            zoom: projectSettings.zoom
          }}
          style={{ height: mapHeight }}
          mapStyle="mapbox://styles/esagis/ck4t6gsva25tr1cpuwxvv2iej"
          mapboxAccessToken={TOKEN}
        >
          {mapLayer?.layers?.map(
            layer =>
              (layer.visible === undefined || layer.visible === true) && (
                <Source
                  id="customLayer"
                  type="geojson"
                  data={layer.data}
                  key={layer.file}
                >
                  <Layer {...dataLayer} />
                </Source>
              )
          )}
          {data?.features?.map(feature => (
            <div key={uuidv4()}>
              <ObservationMarker
                key={`feature + ${uuidv4()}`}
                observation={feature}
                onSelectCity={onSelectCity}
              />
              {feature.properties?.observations?.length > 0 ? (
                feature.properties.observations.map(observation => (
                  <AdditionalObservationMarker
                    key={`observationMarker + ${uuidv4()}`}
                    observation={observation}
                    onSelectCity={onSelectCity}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          ))}
        </Map>
      </Box>
    </div>
  );
};

MapContainer.propTypes = {
  mapHeight: PropTypes.any.isRequired
};

export default MapContainer;
