import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Container } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import Header from '../../views/reports/DashboardView/Header';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  page: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: 64
    }
  }
}));

function DashboardLayout({ children, title, header }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Page className={classes.page} title={title}>
              <Container maxWidth={false} className={classes.container}>
                {header ? <Header /> : <></>}
                {children}
              </Container>
            </Page>
          </div>
        </div>
      </div>
    </div>
  );
}
DashboardLayout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  header: PropTypes.bool
};

export default DashboardLayout;
