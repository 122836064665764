import React from 'react';
import PropTypes from 'prop-types';
import useSettings from 'src/hooks/useSettings';
import { useKeycloak } from '@react-keycloak/web';
import { hasRole } from 'src/lib';

// Shows content if the user has the role, if not does not.
const HasRole = ({ requiredRole, children }) => {
  const { settings } = useSettings();
  const { keycloak } = useKeycloak();

  return hasRole(keycloak, settings.project.toLowerCase(), requiredRole)
    === true ? (
      <>{children}</>
    ) : (
      <></>
    );
};

HasRole.propTypes = {
  requiredRole: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

export default HasRole;
