import React from 'react';
 
import ToolTip from '@material-ui/core/Tooltip'
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons';
 import moment from 'moment';
import { Typography } from '@material-ui/core';
const isRowErrored = ( errors,id) => {
  if(!errors)
  return false;
  console.log('These are the errors',errors)
   let ret = errors.find(err => err.path[0] === id) ? 'Yes' : 'No';
  return ret
};

const disIncrements = [
  {upper:100,lower:78,default:true,color:'pink'},
  {upper:77,lower:50, color:'orange'},
  {upper:50,lower:0, color:'lightgreen'}
]

 const getDisDisplay = (dist)=>{
    
    if(!dist)
      return dist;
    //Find correct item ::
    let item = disIncrements.find(item=>item.upper >=dist && item.lower<=dist);
    if(!item)
      item = disIncrements.find(item=>item.default===true);

    return <div style={{backgroundColor: item.color}}>{dist}m</div>

 }
const isCellErrored = (item, cell, errors) => {
  if(!errors)
    return false;
  let retBl = errors.details.find(
    err => err.path[0] === item.id && err.path[1] === cell
  )
    ? true
    : false;
     
  return retBl;
};
const getCellError = (item, cell, errors) => {
  if(!errors)
  return false;
    const err = errors.details.find(
        err => err.path[0] === item.id && err.path[1] === cell
      );

 
    return err
      ? err.message
      : false;
  };
const dataGridColsFromImport = (importData,fields) => {
  let blHasDataField = false;
  if (importData?.itemData?.data?.value.length === 0) {
   
    return [];
  }

  let firstRow = importData?.itemData?.data?.value[0];
  //get Property count;
  let propCount = Object.keys(firstRow).length+1;
  let cellWidth = ((document.documentElement.clientWidth-400)/propCount) 
   
  let retCols = [
    {
      field: 'errored',
      headerName: 'Errors',
      width: `${cellWidth}`,
      renderCell:(para)=>{return para.value==='Yes'?<ErrorOutline style={{color:'red'}}/>:<CheckCircleOutline style={{color:'green'}}/>}
    }
  ];
  
  

  for (let prop in firstRow) {

    let parenOcc = prop.indexOf('(');
    let normProp = parenOcc > -1 ? prop.substring(0,parenOcc-1).trim() : prop;
    let field = fields.find(
      fi => fi.altNames.indexOf(normProp.toLocaleLowerCase()) > -1
    );
    if(!field)
        field = fields.find(fi=>fi.fieldName.toLocaleLowerCase() === normProp.toLocaleLowerCase());

    let name = field ? field.fieldName : prop;
    let ftype = field ? field.type === 'datetime'?'date':field.type : 'string';
    if(field)
      if(field.isDataRecord===true)
        blHasDataField=true;
    let col = {
      field: prop,
      headerName: name,
      width: `${cellWidth}`,
     type: ftype,
     headerClassName: field ? 'goodCol' : 'badCol',
     
      renderCell: item => {
        let blErrrored = isCellErrored(item, prop, importData?.itemData?.data?.error);
        let error = blErrrored? getCellError(item,prop,importData?.itemData?.data?.error) : '';
         
        return blErrrored ? (
          <ToolTip title={error} style={{fontSize:14}} >
       <Typography style={{ borderRadius: 5,   padding: 5, borderStyle:'dotted' , borderColor:'red'}}> {item.value ? item.value : <div style={{width:50}}>&nbsp;</div>} </Typography>
       </ToolTip>
       ) : ( 
          <div>{ftype==='date'? moment(new Date(item.value)).format('MM/DD/YYYY HH:mm:ss') : item.value}</div>
        );
      }
    };
    retCols.push(col);
    if(prop==='Station'){
      retCols.push({
        field:'Reference',
        headerName : 'Reference',
        width : `${cellWidth}` 
      })
      retCols.push({
        field:'deployOffset',
        headerName : 'Deployment Offset',
        renderCell: param=>{return getDisDisplay(param.value)}
      })
    }
  }

  return {cols:retCols, hasData:blHasDataField};
};

export { dataGridColsFromImport,isCellErrored,isRowErrored };
