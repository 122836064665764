export function hasRole(keycloakContext, client, roleName) {
  const project = client.toLowerCase();
  if (!keycloakContext) {
    console.log('---------- CONTEXT UNDEFINED-----------');

    return false;
  }
  if (!keycloakContext.tokenParsed) {
    console.log('---------- TOKEN NOT PARSED-----------');
    return false;
  }
  if (!keycloakContext.tokenParsed.resource_access[project]) {
    console.log('---------- CLIENT NOT FOUND-----------');
    return false;
  }
  const roleArray = keycloakContext.tokenParsed.resource_access[project].roles;
  const roleFound = roleArray.find((e) => e === roleName);
  return roleFound !== undefined;
}

// get the list of projects for the user, checks for this first, returns list
export function getProjects(keycloakContext) {
  const userContext = keycloakContext;
  if (!userContext) {
    // //console.log('---------- CONTEXT UNDEFINED-----------')
    return false;
  }
  if (!userContext.tokenParsed) {
    /// /console.log('---------- TOKEN NOT PARSED-----------')
    return false;
  }

  const projects = Object.keys(userContext.tokenParsed.resource_access).filter(
    (key) => key !== 'account'
  );

  return projects;
}

// returns boolean if they have access for the project they select
export function hasClient(keycloakContext, client) {
  client = client.toLowerCase();
  if (!keycloakContext) {
    // console.log('---------- CONTEXT UNDEFINED-----------')
    return false;
  }
  if (!keycloakContext.tokenParsed) {
    // console.log('---------- TOKEN NOT PARSED-----------')
    return 'WAIT';
  }
  if (!keycloakContext.tokenParsed.resource_access[client.toLowerCase()]) {
    // console.log('---------- CLIENT NOT FOUND-----------')
    return false;
  }
  // console.log('Parsed Token :: ', keycloakContext.tokenParsed);
  return true;
}
