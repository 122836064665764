import React, { useState, useEffect } from 'react';
import { API_ROOT, MAX_REPORT_EXPORT } from 'src/config';
import PropTypes from 'prop-types';
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector
} from '@mui/x-data-grid-pro';
import { useKeycloak } from '@react-keycloak/web';
import { hasRole } from 'src/lib';
import useSettings from 'src/hooks/useSettings';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import HasRole from 'src/views/auth/HasRole';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

const DashboardDataGridPro = ({
  columnData,
  name,
  dataGridOptions,
  data,
  setDownloading,
  hasRowId
}) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState(dataGridOptions?.filterModel);
  const [loading, setLoading] = useState(true);
  const { keycloak } = useKeycloak();
  const { settings } = useSettings();
  const apiRef = useGridApiRef();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    if (data) {
      const updatedColumns = [];
      const sortedColummns = [];
      columnData.map((column) => {
        if (column.requiresRole) {
          const result = hasRole(
            keycloak,
            settings.project,
            column.requiresRole
          );
          if (!result) {
            return {};
          }
          return updatedColumns.push(column);
        }
        if (column.sort) {
          sortedColummns.push({
            field: column.field,
            sort: column.sort
          });
        }
        return updatedColumns.push(column);
      });

      let updatedData;
      if (!hasRowId) {
        updatedData = data.map((x, i) => ({
          id: i,
          ...x
        }));

        setRows(updatedData);
      } else {
        setRows(data);
      }

      setSortModel(sortedColummns);
      setColumns(updatedColumns);
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [data, columnData, settings.project, keycloak, hasRowId]);

  const downloadBulk = (apiRef) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

    // Format the data. Here we only keep the _record_id
    const updatedIds = [];
    filteredSortedRowIds.map((id) => {
      visibleColumnsField.forEach((field) => {
        if (field === '_record_id') {
          updatedIds.push(apiRef.current.getCellParams(id, field).value);
        }
      });
    });

    if (updatedIds.length >= MAX_REPORT_EXPORT) {
      setError('Max bulk download is 35 reports.');
      setOpen(true);
      return;
    }

    setDownloading(true);
    const method = 'POST';
    const params = new URLSearchParams({
      ids: updatedIds,
      project: settings.project
    });

    const url = `${API_ROOT}Reports/BFDBulk?${params}`;
    axios
      .request({
        url,
        method,
        responseType: 'blob'
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute(
          'download',
          `Reports_BulkExport_${new Date().toString()}.zip`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloading(false);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const BulkDowloadExport = () => {
    const apiRef = useGridApiContext();
    return (
      <MenuItem
        onClick={() => {
          downloadBulk(apiRef);
        }}
      >
        Bulk Dowload
      </MenuItem>
    );
  };

  const csvOptions = { delimiter: ';' };
  // TODO: fix what csv downloads like

  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <HasRole requiredRole="Report Runner">
        <BulkDowloadExport />
      </HasRole>
    </GridToolbarExportContainer>
  );

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
    </GridToolbarContainer>
  );

  return (
    <Box
      sx={{
        height: 500
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ position: 'absolute', marginLeft: -11, marginTop: -12 }}
      >
        <Alert onClose={handleClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <DataGridPro
        sx={{
          color: 'inherit',
          '& .MuiDataGrid-toolbarContainer': {
            button: {
              color: settings.theme === 'LIGHT' ? 'primary.main' : '#e6e5e8'
            }
          },
          '& .MuiToolbar-root': {
            color: settings.theme === 'LIGHT' ? 'text.primary' : '#e6e5e8',
            button: {
              color: settings.theme === 'LIGHT' ? 'text.primary' : '#e6e5e8'
            }
          },
          button: {
            color: settings.theme === 'LIGHT' ? 'text.primary' : '#e6e5e8'
          }
        }}
        apiRef={apiRef}
        aria-label={name}
        components={{
          Toolbar: CustomToolbar
        }}
        loading={loading}
        rows={rows}
        columns={columns}
        disableColumnMenu
        sortModel={sortModel}
        filterModel={filterModel}
        onFilterModelChange={(model) => setFilterModel(model)}
        onSortModelChange={(model) => setSortModel(model)}
        {...dataGridOptions?.props}
      />
    </Box>
  );
};

DashboardDataGridPro.propTypes = {
  columnData: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  dataGridOptions: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  setDownloading: PropTypes.func,
  hasRowId: PropTypes.bool,
};

export default DashboardDataGridPro;
