import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-map-gl';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import { blue, orange, green } from '@mui/material/colors';

const selectedSize = 24;
const normalSize = 18;

export default function ObservationMarker({ observation, onSelectCity }) {
  const selectedItem = useSelector(state => state.mapConfig.selectedItem);
  const selected = selectedItem?.id === observation?.id;

  const getBackGroundColor = () => {
    if (selected) {
      return blue[500];
    }

    if (observation.properties.condition === 'Concern') {
      return orange[400];
    }

    if (observation.properties.condition !== 'Concern') {
      return green[400];
    }
  };

  return (
    <>
      {observation.geometry ? (
        <Marker
          key={observation?.properties?._record_id || observation?.id}
          longitude={observation.geometry.coordinates[0]}
          latitude={observation.geometry.coordinates[1]}
          onClick={event => onSelectCity(event.target._lngLat, observation)}
          anchor="center"
          style={{ position: 'absolute', top: 0, zIndex: 2 }}
          pitchAlignment="viewport"
        >
          <Box sx={{ m: 1, position: 'relative' }}>
            <Avatar
              sx={{
                bgcolor: getBackGroundColor(),
                width: selected ? selectedSize : normalSize,
                height: selected ? selectedSize : normalSize,
                boxShadow: '0 5px 8px 0 rgb(0 0 0 / 19%)'
              }}
            >
              <Typography variant="caption" component="div" color="inherit">
                {observation?.properties?.category?.substring(0, 1) ||
                  observation?.properties?.discipline?.substring(0, 1)}
              </Typography>
            </Avatar>
            {selected ? (
              <CircularProgress
                size={selectedSize + 10}
                thickness={6}
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  top: -5,
                  left: -5,
                  zIndex: 3
                }}
              />
            ) : (
              <></>
            )}
          </Box>
        </Marker>
      ) : (
        <></>
      )}
    </>
  );
}

ObservationMarker.propTypes = {
  observation: PropTypes.objectOf(PropTypes.any).isRequired,
  onSelectCity: PropTypes.func
};
