import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';

const Logout = () => {
  const { keycloak } = useKeycloak();

  return (
    <Box mt={2}>
      <Button
        color="secondary"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => keycloak.logout()}
      >
        Log out
      </Button>
    </Box>
  );
};

export default Logout;
