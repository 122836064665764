import {
  Button,
  Chip,
  Dialog,
  DialogTitle,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useSettings from 'src/hooks/useSettings';
import { COLORS } from 'src/config';
import Client from 'esa-gcclient';
import { calcDistance } from 'esa-gcclient/geo';
import { shadeColor } from 'esa-gcclient/color';
import SimpleMetric from 'src/views/reports/DashboardView/SimpleMetric';
import { Check, Clear, Info } from '@material-ui/icons';
import { hasRole } from 'src/lib';
import { useKeycloak } from '@react-keycloak/web';
import Map from '../map';
import DataGrid from './components/wqGrid';
import { WQProfile } from './components/wqGrid/wqProfile';
import Continuous from './continuous';
import { metricDisplay } from './dashboard/defaultSettings';
import ImportData from './import';
import Latest from './latest';
import StrataPie from './charts/strataPie';

const mainHeight = '45vh';

const mainStyle = {
  minHeight: mainHeight,
  height: '100%',
  display: 'flex'
};

export default function AnalyticsHome() {
  const para = useParams();
  const { settings, saveSettings } = useSettings();
  const { keycloak } = useKeycloak();
  const [addMarkers, setAddMarkers] = useState();
  const [addLines, setAddLines] = useState();
  const [mapData, setMapData] = useState();
  const [mapSel, setMapSel] = useState();
  const [dialogOpen, setDiagOpen] = useState();
  const [selProfiles, setSelProfiles] = useState();
  const [profileData, setProfileData] = useState();
  const [cDepths, setCDepths] = useState();
  const [cMetrics, setCMetrics] = useState();
  const [stats, setStats] = useState({ maxDate: '' });
  const [strataCount, setStrataCount] = useState();
  const [labCount, setLabCount] = useState();

  useEffect(() => {
    const statsObj = stats;
    Client.getDataByObj({
      tableName: 'deployment',
      deploymentId: 'count'
    }).then((result) => {
      statsObj.recordCount = result[0].recCount;
      setStats(statsObj);
    });
    Client.getDataByObj({ tableName: 'deployment', tStamp: 'max' }).then(
      (result) => {
        statsObj.maxDate = result[0].tStamp;
        setStats(statsObj);
      }
    );
    Client.getData('strataProfileCounts').then((result) => setStrataCount(result.data));
    Client.getData('strataLabCount').then((result) => setLabCount(result.data));
    if (!settings.wqMetricDisplay) {
      settings.wqMetricDisplay = metricDisplay;
      saveSettings(settings);
    }
  }, [settings, saveSettings, stats]); // eslint-disable-next-line react-hooks/exhaustive-deps
  const importModule = () => (
    <Paper>
      <ImportData />
    </Paper>
  );

  const metrics = () => (
    <Grid container>
      <Grid item xs={12} md={3}>
        {' '}
        <SimpleMetric title="Total Profiles" value={stats.recordCount} />
      </Grid>
      <Grid item xs={12} md={3}>
        {' '}
        <SimpleMetric
          title="Latest Data"
          value={new Date(stats.maxDate).toLocaleDateString()}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        {' '}
        <SimpleMetric />
      </Grid>
      <Grid item xs={12} md={3}>
        {' '}
        <SimpleMetric />
      </Grid>
    </Grid>
  );

  const singleMetrics = <Paper>{!selProfiles && metrics()}</Paper>;

  const doChartData = (dataSet, profs) => {
    const profileNames = [];

    profs.map((a) => profileNames.push(a.toString()));
    const _depths = [];
    const _metrics = [];

    let donDist = false;
    dataSet.data.forEach((a) => {
      if (!donDist) {
        donDist = true;
      }
      a.lat = a._latitude;
      a.lng = a._longitude;
      const res = _metrics.find((met) => met.metricName === a.metricName);
      if (!res) {
        const dItem = new Array(profs.length + 1).fill(undefined);
        dItem[0] = a.depth;
        dItem[[profs.indexOf(a.profileId) + 1]] = a.dataValue;

        _metrics.push({
          metricName: a.metricName,
          max: a.dataValue,
          min: a.dataValue,
          metricUnit: a.unitName,
          chartData: [['depth', ...profileNames], dItem]
        });
      } else {
        if (a.dataValue > res.max) res.max = a.dataValue;
        if (a.dataValue < res.min) res.min = a.dataValue;
        if (a.replicate) {
          let pushed = false;
          res.chartData.map((dataItem) => {
            if (dataItem[0] === a.depth) {
              dataItem[profs.indexOf(a.profileId) + 1] = a.dataValue;
              pushed = true;
            }
          });
          if (!pushed) res.chartData.push([a.depth, undefined, a.dataValue]);
        } else {
          let pushed = false;
          res.chartData.map((dataItem) => {
            if (dataItem[0] === a.depth) {
              dataItem[profs.indexOf(a.profileId) + 1] = a.dataValue;
              pushed = true;
            }
          });
          if (!pushed) {
            const dItem = new Array(profs.length + 1).fill(undefined);
            dItem[0] = a.depth;
            dItem[[profs.indexOf(a.profileId) + 1]] = a.dataValue;
            res.chartData.push(dItem);
          }
        }
      }
      if (a.depth) {
        if (_depths.indexOf(a.depth) === -1) _depths.push(a.depth);
      }
    });

    _depths.sort((a, b) => a - b);

    _metrics.map((met) => {
      let maxLength = 0;
      met.chartData.map((dt) => {
        if (dt.length > maxLength) {
          maxLength = dt.length;
        }
      });

      met.chartData.map((dt, ndx) => {
        if (dt.length < maxLength) {
          if (ndx === 0) {
            dt.push('replicate');
          } else {
            dt.push(undefined);
          }
        }
      });
    });
    setCDepths(_depths);
    setCMetrics(_metrics);
  };

  const selProfileGrid = () => (
    <>
      {console.log('This is the profile data', profileData)}
      {profileData && (
        <Paper style={{ minHeight: 300, height: '100%' }}>
          <DataGrid
            rows={profileData}
            editMode="cell"
            saveTo="dataRecord"
            noToolbar
            density="compact"
            loading={false}
            columns={[
              {
                field: 'tStamp',
                headerName: 'Date',
                width: 150,
                editable: hasRole(keycloak, 'tbw', 'data-edit-profiles'),
                renderCell: (para) => new Date(para.value).toLocaleDateString()
              },
              { field: 'stationName', headerName: 'Station', width: 150 },
              { field: 'metricName', headerName: 'Metric', width: 175 },
              {
                field: 'depth',
                headerName: 'Depth',
                width: 150,
                editable: hasRole(keycloak, 'tbw', 'data-edit-profiles')
              },
              {
                field: 'dataValue',
                headerName: 'Result',
                width: 150,
                editable: hasRole(keycloak, 'tbw', 'data-edit-profiles')
              }
            ]}
          />
        </Paper>
      )}
    </>
  );

  const doProfiles = (arrItems) => {
    Client.getData(
      'geoProfile',
      `profileId in (${arrItems.join(',')})`,
      false
    ).then(
      (result) => {
        result.data.map((a) => (a.id = a.recordId));
        setProfileData(result.data);
        doChartData(result, arrItems);
        const stations = [];
        const profiles = [];
        const lines = [];
        result.data.map((item) => {
          let station = stations.find((a) => a.stationName === item.stationName);
          if (!station) {
            station = {
              ...item,
              _latitude: item.stationLat,
              _longitude: item.stationLng
            };
            station.color = COLORS[stations.length];

            station.tip = (
              <Chip
                variant="default"
                label={`Station ${station.stationName}`}
                style={{ color: '#0000ff', backgroundColor: 'whitesmoke' }}
              />
            );
            stations.push(station);
            item.color = shadeColor(station.color, 45);
            item.selected = true;

            item.dist = calcDistance(
              item._latitude,
              item._longitude,
              item.stationLat,
              item.stationLng
            );
            item.tip = (
              <Chip
                variant="default"
                label={`Profile ${item.profileId} - ${Math.round(
                  item.dist * 1000,
                  1
                )}m from ${station.stationName}`}
                style={{ color: '#0000ff', backgroundColor: 'whitesmoke' }}
              />
            );
            profiles.push(item);
            const line = {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: [
                  [parseFloat(item._longitude), parseFloat(item._latitude)],
                  [
                    parseFloat(station._longitude),
                    parseFloat(station._latitude)
                  ]
                ]
              }
            };
            lines.push(line);
          } else {
          }
        });

        const Linesret = { type: 'FeatureCollection', features: lines };
        setAddLines(Linesret);
        setAddMarkers([...stations, ...profiles]);
        if (profiles.length === 1) setMapSel(profiles[0]);
      },
      (error) => console.log('error', error)
    );
  };

  const gridSelectionChange = (arrItems, rows) => {
    doProfiles(arrItems);
    setSelProfiles(arrItems);
    rows.map((p) => (p.colorRef = undefined));
    arrItems.map((a, ndx) => {
      const p = rows.find((x) => x.profileId === a);
      if (p) {
        p.colorRef = (
          <Button
            style={{ backgroundColor: COLORS[ndx], maxWidth: 2, height: 10 }}
          />
        );
      }
    });
  };

  const allData = () => (
    <Grid container spacing={1} style={{ padding: 5 }}>
      <Grid item xs={12} lrg={12}>
        {singleMetrics}
      </Grid>
      <Grid item xs={12} lrg={12}>
        <Typography variant="h5" style={{ padding: 10 }}>
          <Chip
            icon={<Info />}
            variant="outlined"
            label="To get started, click a profile in the grid. You can select multiple profiles by holding down CTRL or Command (Mac) and clicking."
          />
        </Typography>
      </Grid>
      <Dialog open={dialogOpen}>
        <DialogTitle onClick={() => setDiagOpen(false)}>
          Profile Data
        </DialogTitle>
      </Dialog>
      <Grid item xs={12} md={7} lrg={7}>
        <Paper style={{ height: '100%', padding: 0, margin: 0 }}>
          <DataGrid
            onSelectionModelChange2={gridSelectionChange}
            style={mainStyle}
            sortModel={[{ field: 'tStamp', sort: 'desc' }]}
            table="profiles"
            MapSub={(evt) => setMapData(evt)}
            overCb={(e) => setMapSel(e.row)}
            density="compact"
            order="tStamp"
            columns={[
              {
                headerName: '',
                field: 'colorRef',
                hide: !selProfiles,
                width: 50,
                renderCell: (para) => (para.value ? para.value : '')
              },
              {
                field: 'tStamp',
                headerName: 'Date',
                width: 150,
                renderCell: (para) => new Date(para.value).toLocaleDateString()
              },
              { field: 'profileId', headerName: 'Profile Id', width: 150 },
              { field: 'strataName', headerName: 'Strata', width: 150 },
              { field: 'stationName', headerName: 'Station', width: 150 },
              {
                field: 'secciDepth',
                headerName: 'Secchi Depth',
                disableColumnMenu: true,
                width: 110
              },
              {
                field: 'bottomVisible',
                headerName: 'Bottom Vis.',
                disableColumnMenu: true,
                width: 110,
                renderCell: (props) => (props.value === true ? <Check /> : <Clear />)
              }
            ]}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={5} lrg={5}>
        <Map
          style={mainStyle}
          SubData={mapData}
          selItem={mapSel}
          selZoom={17}
          lat={28}
          lng={-82.42}
          renderGeoJSON
          markerText="D"
          AdditionalMarkers={addMarkers}
          AddLines={addLines}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {cMetrics && <WQProfile Metrics={cMetrics} Depths={cDepths} />}
      </Grid>
      <Grid item xs={12} md={6}>
        {selProfileGrid()}
      </Grid>
      {strataCount && (
        <>
          <Grid item xs={6} md={6}>
            <StrataPie
              title="Profile Count by Strata"
              style={{ height: '40vh' }}
              data={strataCount}
              displayField="strataName"
            />
          </Grid>
          {labCount && (
            <Grid item xs={6} md={6}>
              <StrataPie
                title="Profiles without Labs"
                style={{ height: '40vh' }}
                data={labCount}
                displayField="profileOnly"
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );

  const getContent = () => {
    switch (para.mode) {
      case 'all':
        return allData();
      case 'import':
        return importModule();
      case 'latest':
        return <Latest />;
      case 'continous':
        return <Continuous />;
      default:
        return allData();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        {/*    {getContent()} */}
      </Grid>
    </Grid>
  );
}
