import { GridToolbar, DataGridPro } from '@mui/x-data-grid-pro';

import * as React from 'react';

export default function GridPro(props) {
  const didFilter = (evt) => {
  };
  const didSort = (evt) => {
  };
  const setMapRows = (evt) => {
    if (props.MapSub) {
      props.MapSub({
        rows: props.rows.slice(evt.firstRowIndex, evt.lastRowIndex)
      });
    }
  };
  const selChanged = (evnt) => {
    // Find correct item and bubble up =>

    const item = props.rows.find((item) => item.id === evnt[0]);
    if (props.Selected) {
      props.Selected(item);
    }
  };
  return (
    <DataGridPro
      onViewportRowsChange={setMapRows}
      onFilterModelChange={didFilter}
      onSortModelChange={didSort}
      onSelectionModelChange={selChanged}
      components={{
        Toolbar: GridToolbar
      }}
      {...props}
    />
  );
}
