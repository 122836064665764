import React from 'react';
import { renderLink, renderLabel, renderDate } from 'src/utils/mui-grid-helpers';
import InteractiveButton from 'src/views/reports/DashboardView/DataGridPro/InteractiveIconButton';
import FileUpload from './FileUpload';
import FileDelete from './FileDelete';

const DailyReportColumns = [
  {
    field: 'field_person',
    headerName: 'Field Person',
    flex: 1,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'date_general',
    headerName: 'Report Date',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    type: 'date',
    sort: 'desc',
    renderCell: (arg) => renderDate(arg)
  },
  {
    field: 'discipline',
    headerName: 'Discipline',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (arg) => renderLabel(arg)
  },
  {
    field: '_record_id',
    headerName: 'View',
    flex: 0.5,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    renderCell: (arg) => renderLink(`/app/DailyReports/${arg.value}`)
  },
  {
    field: 'download_report',
    headerName: 'Download PDF',
    flex: 0.5,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    renderCell: (arg) => <InteractiveButton report={arg} />
  },
  {
    field: 'delete',
    headerName: 'Delete',
    sortable: false,
    filterable: false,
    requiresRole: 'DeleteMonitoring',
    flex: 0.5,
    align: 'center',
    headerAlign: 'center',
    renderCell: (arg) => <FileDelete recordID={arg.value} />
  },
  {
    field: 'files',
    headerName: 'Files',
    filterable: false,
    sortable: false,
    requiresRole: 'Attachments',
    flex: 0.5,
    align: 'center',
    headerAlign: 'center',
    renderCell: (arg) => <FileUpload recordID={arg.value} />
  }
];

export default DailyReportColumns;
