import React from 'react';

import { Paper, IconButton, Typography } from '@material-ui/core';
import AttachmentButton from 'src/controls/attachments/attachmentButton';
import { AddToPhotos } from '@material-ui/icons';
import Attachments from 'src/controls/attachments';
import useSettings from 'src/hooks/useSettings';
import { useDispatch } from 'react-redux';
import { setLayers } from 'src/reducers/mapLayerReducer';
import HasRole from '../auth/HasRole';
import { getLayers } from './layerUtils';

// FIX: this component is giving the validatingDOM nesting error message, it's the button order
const MapToolBar = (props) => {
  const [showA, setShowA] = React.useState(false);
  const [f, setF] = React.useState();
  const { settings } = useSettings();
  const dispatch = useDispatch();

  const closeDialog = () => {
    setShowA(false);
    setF(Math.random());
  };
  const updateRedux = () => {
    getLayers(settings).then((layersResult) => {
      dispatch(setLayers(layersResult));
    });
  };
  return (
    <HasRole requiredRole="GIS-EDITOR">
      <Paper style={{ textAlign: 'left' }}>
        <IconButton onClick={() => setShowA(true)} role="button">
          <AttachmentButton
            icon={AddToPhotos}
            iconSize={24}
            forcedUpdate={f}
            bindGuid="MAP-FILES"
          />
          {' '}
          &nbsp;&nbsp;
          <Typography>Add or Remove Layers</Typography>
        </IconButton>
        <Attachments
          onComplete={updateRedux}
          title="Map Layers"
          showHide={showA}
          setShowHide={closeDialog}
          bindGuid="MAP-FILES"
        />
      </Paper>
    </HasRole>
  );
};

export default MapToolBar;
