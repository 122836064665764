import React, { useEffect, useState } from 'react';

import { DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid';



export default function UploadErrors({ errorList }) {
if(!errorList)
return (<></>)
return(
  <div style={{height:400}}>
    {console.log('trying to render error list')}
  <DataGrid rows={errorList} pageSize={5} {...errorList} />
  </div>
);

}