import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Box,
  Typography,
  Chip
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { COLORS } from 'src/config';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
export const WQProfile = (props) => {
  const [metrics, setMetrics] = React.useState(
    props.Metrics ? props.Metrics : []
  );
  const [tabNumber, setTabNumber] = React.useState(0);
  const [, setDepths] = React.useState(props.Depths);
  const [fake, setFake] = React.useState(0);
  useEffect(() => {
    setMetrics(props.Metrics);
  }, [props.Metrics]);

  const chart = (metric) => (
    <Paper>
      <Chart
        height={300}
        chartType="Scatter"
        legend_toggle={false}
        loader={<div>loading..</div>}
        data={metric.chartData}
        options={{
          colors: COLORS,
          is3D: true,
          legend: {
            position: 'none'
          },
          backgroundColor: '00000000',
          chart: {
            legend: {
              position: 'none'
            },
            fontSize: 8,
            pointSize: '2',

            pointShape: 'star'
          },
          animation: {
            duration: 1000,
            easing: 'inAndOut'
          },
          title: `${metric.metricName} vs Depth`
        }}
      />
    </Paper>
  );
  return props.Metrics ? (
    <Paper elevation={8}>
      <Grid container padding={5}>
        <Grid item md={12}>
          <Tabs value={tabNumber} onChange={(e, x) => setTabNumber(x)}>
            {props.Metrics.length > 0
              && props.Metrics.map((met, ndx) => (
                <Tab label={met.metricName.replace('_', ' ')} />
              ))}
          </Tabs>
          {props.Metrics.length > 0
            && props.Metrics.map((met, ndx) => (
              <>
                {' '}
                <TabPanel index={ndx} value={tabNumber}>
                  <Grid container>
                    <Grid item xs={12}>
                      {' '}
                      <Chip
                        variant="outlined"
                        label={`Min: ${met.min}${met.metricUnit}`}
                      />
                      <Chip
                        variant="outlined"
                        label={`Max: ${met.max}${met.metricUnit}`}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {' '}
                      {chart(met)}
                      {' '}
                    </Grid>
                  </Grid>
                </TabPanel>
              </>
            ))}
        </Grid>
      </Grid>
    </Paper>
  ) : (
    <></>
  );
};
