import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Attachments from 'src/controls/attachments';
import AttachmentButton from 'src/controls/attachments/attachmentButton';

const FileUpload = ({ recordID }) => {
  const [showFiles, setShowFiles] = useState();
  const [fUpdate, setFUpdate] = useState();

  const hideShowFileDiag = (hideShow) => {
    setShowFiles(hideShow);
    setFUpdate(Math.random());
  };

  return (
    <>
      <IconButton
        color="primary"
        size="small"
        onClick={() => setShowFiles(true)}
      >
        <AttachmentButton bindGuid={recordID} forcedUpdate={fUpdate} />
      </IconButton>
      <Attachments
        bindGuid={recordID}
        showHide={showFiles}
        setShowHide={hideShowFileDiag}
      />
    </>
  );
};

export default FileUpload;

FileUpload.propTypes = {
  recordID: PropTypes.string
};
