import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import React, { useState, useEffect } from 'react';
import Client from 'esa-gcclient';
import PropTypes from 'prop-types';

const esaClient = require('esa-gcclient');

export default function DataGrid(props) {
  const [loading, setLoading] = useState(true);
  const [gData, setGData] = useState();

  const getData = async () => {
    const data = await esaClient.getXGridData(props.table, undefined, true);
    data.rows.map(a => {
      a._longitude = a.lng;
      a._latitude = a.lat;
      a.colorRef = undefined;
    });

    setLoading(false);

    return data;
  };

  useEffect(() => {
    if (!props.rows) {
      getData().then(result => setGData(result));
    }
  }, [props.table]);

  const setMapRows = (evt, x, apiRef) => {
    if (props.MapSub) {
      const r = Array.from(apiRef.api.getVisibleRowModels()).slice(
        evt.firstRowIndex,
        evt.lastRowIndex
      );
      const rr = [];
      r.map(item => rr.push(item[1]));

      props.MapSub({ rows: rr });
    }
  };
  
  const selChange = arrItems => {
    if (props.onSelectionModelChange2)
      props.onSelectionModelChange2(arrItems, gData.rows);
  };

  const commitCell = vals => {
    const saveObj = {
      id: vals.id,
      tableName: props.saveTo ? props.saveTo : props.table
    };
    saveObj[vals.field] = vals.value;
    Client.blindSave(saveObj);
  };

  return (
    <>
      {(gData || props.rows) && (
        <DataGridPro
          onCellEditCommit={commitCell}
          onSelectionModelChange={selChange}
          loading={loading}
          rows={props.rows ? props.rows : gData.rows}
          columns={props.columns ? props.columns : gData.columns}
          components={
            !props.noToolbar && {
              Toolbar: GridToolbar
            }
          }
          onViewportRowsChange={setMapRows}
          {...props}
        />
      )}
    </>
  );
}

// TO DO: finish proptypes
DataGrid.propTypes = {
  table: PropTypes.string,
  MapSub: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string,
      hide: PropTypes.bool,
      renderCell: PropTypes.func,
      width: PropTypes.number
    })
  )
};
