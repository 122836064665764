import { createSlice } from '@reduxjs/toolkit';

export const ProjectSettings = createSlice({
  name: 'projectSettings',
  initialState: { },
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    }
  }
});

export const projectSettings = state => state.settings;

export const { setSettings } = ProjectSettings.actions;
export default ProjectSettings.reducer;
