import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import useSettings from 'src/hooks/useSettings';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import { Card, CardContent, Typography } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { API_BFD_V2_ROOT } from 'src/config';

const DownloadReports = () => {
  const { keycloak } = useKeycloak();
  const { settings } = useSettings();
  const [reportOptions, setReportOptions] = useState([]);
  const [report, setReport] = useState({});
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    const reqInstance = axios.create({
      headers: {
        kctoken: `${keycloak.token}`
      }
    });

    reqInstance.get(`${API_BFD_V2_ROOT}rpt/config`).then((response) => {
      const filteredResp = response.data.filter((data) => data.appName.toLowerCase().includes(settings.project.toLowerCase()));
      setReportOptions(filteredResp);
    });
  }, [keycloak.token, settings.project]);

  const handleDownload = type => {
    setDownloading(true);
    let url = '';
    if (type === 'pdf') {
      url = `${API_BFD_V2_ROOT}getImageReport/${report?.appName}/${report?.reportName}`;
    }
    if (type === 'kml') {
      url = `${API_BFD_V2_ROOT}getImageReport/kml/${report?.appName}/${report?.reportName}`;
    }

    const date = new Date();
    const currentDate = moment(
      new Date(date.getFullYear(), date.getMonth(), date.getDate())
    ).format('MM-DD-YYYY');

    axios
      .request({
        url,
        method: 'GET',
        responseType: 'blob'
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute(
          'download',
          `${report?.reportName}_${currentDate.toString()}.${type}`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloading(false);
      });
  };

  return (
    <>
      {reportOptions.length > 0 ? (
        <Grid item xs={12}>
          <Card>
            <LoadingOverlay
              active={downloading}
              text={<Typography>Downloading Reports, please wait..</Typography>}
              spinner
            >
              <CardContent>
                <Box sx={{ width: '100%' }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ flexWrap: 'nowrap' }}
                  >
                    <Box sx={{ flex: 2 }}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-reports">REPORTS</InputLabel>
                        <Select
                          labelId="select-reports"
                          id="select-reports"
                          value={report?.title}
                          label="REPORTS"
                          onChange={(event) => setReport(event.target.value)}
                        >
                          {reportOptions?.map((report) => (
                            <MenuItem
                              key={report._id}
                              value={report}
                              sx={{ flex: 1 }}
                            >
                              {report.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<DownloadIcon />}
                      onClick={() => handleDownload('pdf')}
                      disabled={!report?.title}
                    >
                      Download PDF
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      endIcon={<DownloadIcon />}
                      onClick={() => handleDownload('kml')}
                      disabled={!report?.title}
                    >
                      Download KML
                    </Button>
                  </Stack>
                </Box>
              </CardContent>
            </LoadingOverlay>
          </Card>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default DownloadReports;
