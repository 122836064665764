import Client from 'esa-gcclient';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ImportItem from './importItem';



export const Imports = () => {
  const importItems = useSelector(state => state.imports.importFiles);
 
  const testArry = ['date time', 'date']
  console.log('Index of test',testArry.indexOf('time'))
  return importItems.map(item => (
    <>
      <ImportItem itemData={item}  />
    </>
  ));
};

export default Imports;
