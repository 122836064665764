/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-alert */
import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import axios from 'axios';
import { API_BFD_V2_ROOT } from 'src/config';
import { useKeycloak } from '@react-keycloak/web';
import useSettings from 'src/hooks/useSettings';
import HasRole from 'src/views/auth/HasRole';
import { useSelector, useDispatch } from 'react-redux';
import { saveDashboardData } from 'src/reducers/dashboardReducer';
import { setSelectedItem } from 'src/reducers/mapLayerReducer';
import DataGridMapInteraction from 'src/views/reports/DashboardView/DataGridPro/DataGridMapInteraction';
import DownloadReports from 'src/components/DownloadReports';
import SimpleMetric from './SimpleMetric';
import DataCardFlipContainer from './DataGridPro/DataCardFlipContainer';
import DailyReportColumns from './DataGridPro/DailyReportColumns';
import TrackableColumns from './DataGridPro/TrackableColumns';
import { getTrackableOptions } from './DataGridPro/TrackableOptions';
import DailyReportOptions from './DataGridPro/DailyReportOptions';

const DashboardView = () => {
  const dashboardData = useSelector((state) => state.dashboardData);
  const projectSettings = useSelector((state) => state.projectSettings);
  const { settings } = useSettings();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const lastWeek = () => {
    const today = new Date();
    const lw = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    );
    return lw.toLocaleDateString();
  };

  useEffect(() => {
    const fetchData = async () => {
      const reqInstance = axios.create({
        headers: {
          kctoken: `${keycloak.token}`
        }
      });

      const trackables = await reqInstance
        .post(`${API_BFD_V2_ROOT}fulcrumServe/query`, {
          formName: `${settings.project} Observations`,
          filter:
            "initial_date_of_trackable  > '6/1/1999' AND trackable_id<>''",
          fields: [
            'trackable_id',
            'initial_date_of_trackable',
            '_updated_at',
            'location_of_observation',
            'condition',
            'category',
            '_status',
            '_record_id',
            'general_activity',
            '_geometry',
            'follow_up_yes_no'
          ]
        })
        .then((response) => {
          const updatedTrackables = response.data.map((row, index) => ({
            ...row,
            id: index
          }));
          return updatedTrackables;
        });
      dispatch(saveDashboardData({ data: trackables, key: 'trackables' }));

      const dailyReport = await reqInstance
        .post(`${API_BFD_V2_ROOT}fulcrumServe/query`, {
          formName: `${settings.project} Monitoring`,
          filter: "date_general > '6/1/1999' AND _status <> 'survey'",
          fields: [
            'field_person',
            'date_general',
            'discipline',
            '_record_id',
            'download_report',
          ]
        })
        .then((response) => response.data.map((row, index) => ({
          ...row,
          id: index,
          delete: row._record_id,
          files: row._record_id
        })));
      dispatch(saveDashboardData({ data: dailyReport, key: 'dailyReports' }));

      const recentComms = await reqInstance
        .post(`${API_BFD_V2_ROOT}fulcrumServe/query`, {
          formName: `${settings.project} Communication`,
          filter: `date > '${moment(lastWeek()).format('MM-DD-YYYY')}'`,
          fields: [
            'field_person',
            'contacted',
            'date',
            '_record_id',
            'discussed'
          ]
        })
        .then((response) => response.data.sort((a, b) => new Date(b.date) - new Date(a.date)));
      dispatch(
        saveDashboardData({
          data: recentComms,
          key: 'communications'
        })
      );
    };

    fetchData();
    dispatch(setSelectedItem(null));
    dispatch(saveDashboardData({ data: [], key: 'bulkReportIds' }));
  }, [dispatch, keycloak.token, settings.project]);

  return (
    <Grid container spacing={3}>
      <Grid item lg={3} sm={6} xs={12}>
        <SimpleMetric title="Total Trackable Items" icon="track" />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <SimpleMetric title="Open Compliance Concerns" icon="track" />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <SimpleMetric title="Open Trackable Items" icon="track" />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <SimpleMetric title="Total Daily Reports" icon="track" />
      </Grid>
      <Grid item lg={7} xs={12}>
        {/* DAILY RERPORTS DATA GRID */}
        <DataCardFlipContainer
          name="Daily Reports"
          chartOptions={{
            indexBy: 'field_person',
            title: 'Report count by person',
            groupOptions: [
              { value: 'field_person', label: 'Field Person' },
              { value: 'discipline', label: 'Discipline' }
            ]
          }}
          dataGridOptions={DailyReportOptions}
          columnData={DailyReportColumns}
          reduxData={dashboardData?.dailyReports}
        />
      </Grid>

      {/* COMMUNICATIONS DATA GRID */}
      <Grid item lg={5} xs={12}>
        <DataCardFlipContainer
          name="Recent Communications"
          chartOptions={{
            indexBy: 'contacted',
            title: 'Communications by contacted person.',
            groupOptions: [
              { value: 'contacted', label: 'Contacted Party' },
              { value: 'field_person', label: 'Field Person' }
            ]
          }}
          reduxData={dashboardData?.communications}
        />
      </Grid>

      <DownloadReports />

      <HasRole requiredRole="Trackables">
        <Grid item xs={12}>
          <DataGridMapInteraction
            name="Trackable Items"
            chartOptions={{
              indexBy: 'category',
              title: 'Trackables by category',
              groupOptions: [
                { value: 'category', label: 'Category' },
                { value: 'condition', label: 'Condition' },
                { value: '_status', label: 'Status' }
              ]
            }}
            dataGridOptions={getTrackableOptions(projectSettings)}
            columnData={TrackableColumns}
            data={dashboardData.trackables}
          />
        </Grid>
      </HasRole>
    </Grid>
  );
};

export default DashboardView;
