/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  makeStyles
} from '@material-ui/core';
import Client from 'esa-gcclient';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  BarChart2,
  CheckCircle,
  Download,
  PieChart as PieChartIcon,
  Sunrise,
  Thermometer,
  Database,
  DownloadCloud,
  Search
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import ListForNav from './ListForNav';

const continuousSites = async () => {
  const sites = [];
  Client.getData('station', 'continuous=1').then((resp) => {
    resp.data.map((item) => sites.push({
      title: item.stationName,
      href: `/app/analytics/waterquality/continous/${item.stationName}`,
      icon: DownloadCloud
    }));
    return sites;
  });

  const results = await Client.getData('station', 'continuous=1');
  return results;
};

export const navConfig = async () => {
  const sites = [];
  continuousSites().then((resp) => {
    resp.data.map((item) => sites.push({
      title: item.stationName,
      href: `/app/analytics/waterquality/continous/${item.stationName}`,
      icon: DownloadCloud
    }));
  });

  return [
    {
      id: 0,
      subheader: 'Field Data Collection',
      role: 'fdc-dash',
      items: [
        {
          title: 'Construction Monitoring',
          href: '/app/reports/dashboard',
          items: [
            {
              title: 'Reports Dashboard',
              href: '/app/reports/dashboard',
              icon: PieChartIcon
            },

            {
              role: 'advancedSearch',
              title: 'Advanced Search',
              href: '/app/reports/advanced-search',
              icon: Search
            }
          ]
        }
      ]
    },
    {
      id: 1,
      subheader: 'Field Data Collection',
      role: 'fdc-dash-alt',
      items: [
        {
          title: 'Construction Monitoring',
          items: [
            {
              title: 'Reports Dashboard',
              href: '/app/reports/dashboard',
              icon: PieChartIcon
            }
          ]
        }
      ]
    },
    {
      id: 2,
      subheader: 'Analytics',
      role: 'wq-dash',
      items: [
        {
          title: 'Water Quality',
          icon: Thermometer,
          href: '/app/analytics/waterquality',
          items: [
            {
              title: 'View Historic Data',
              href: '/app/analytics/waterquality',
              icon: BarChart2,
              items: [
                {
                  title: 'Profile Data',
                  href: '/app/analytics/waterquality/all',
                  icon: Database
                },
                {
                  title: 'Continuous Data',
                  href: '/app/analytics/waterquality/continous',
                  icon: DownloadCloud,

                  items: sites
                },
                {
                  title: 'Lab Data',
                  href: '/app/analytics/waterquality/latest',
                  icon: Sunrise
                }
              ]
            },
            {
              title: 'Import Data from Devices',
              icon: Download,
              href: '/app/analytics/waterquality/import'
            },
            {
              title: 'Quality Check',
              href: '/app/analytics/waterquality/qc',
              icon: CheckCircle
            }
          ]
        }
      ]
    }
  ];
};

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const [navC, setNavC] = React.useState();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    navConfig().then((conf) => setNavC(conf));
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        <Divider />
        <Box p={2}>
          {navC?.map(
            (config, index) => (
              <ListForNav key={index} config={config} />
            )
          )}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
