import { Grid, IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import { API_BASE_URL, Queries } from 'src/config';
import GridPro from 'src/controls/GridPro';
import axios from 'axios';
import { GrDocumentText } from 'react-icons/gr';

const gridCols = [
  {
    field: 'date',
    headerName: 'Date',
    width: 150,
    renderCell: (para) => new Date(para.value).toLocaleDateString()
  },
  { field: 'depth_feet', headerName: 'Depth (ft)', width: 150 },
  { field: 'boring_label', headerName: 'Boring', width: 150 },
  { field: 'formation_type', headerName: 'Formation Type', width: 350 },
  {
    field: '_parent_id',
    header: 'Report',
    renderCell: (para) => (
      <IconButton
        onClick={() => (window.location.href = `/app/reports/dashboard/detail/${para.value}`)}
      >
        <GrDocumentText style={{ color: '#bd46ff' }} />
      </IconButton>
    )
  }
];

const Fossils = (props) => {
  const [fData, setFData] = React.useState();
  const getFossilData = async () => {
    const result = await axios.post(API_BASE_URL, {
      q: Queries('ALT_FOSSILS')
    });
    return result.data;
  };
  useEffect(() => {
    getFossilData().then((data) => {
      setFData(data);
    });
  }, []);
  return (
    <Grid container>
      {fData && (
        <Grid item xs={12} xl={12}>
          <GridPro
            rows={fData.rows}
            columns={gridCols}
            style={{ height: '65vh' }}
            {...props}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Fossils;
