import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { THEMES } from 'src/constants';
import { storeSettings } from 'src/utils/settings';
import { getProjects } from 'src/lib';
import { useKeycloak } from '@react-keycloak/web';

const SettingsContext = createContext();

export function SettingsProvider({ settings, children }) {
  const { keycloak } = useKeycloak();
  const userProjectList = getProjects(keycloak);

  let defaultProject;
  if (!settings) {
    if (!userProjectList || userProjectList.length === 0) {
      defaultProject = 'SDDR';
    } else {
      defaultProject = userProjectList[0].toUpperCase();
    }
  }

  const defaultSettings = {
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: THEMES.ONE_DARK,
    project: defaultProject
  };

  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object
};

export const SettingsConsumer = SettingsContext;

export default SettingsContext;
