import Joi from 'joi';

const buildJoiField = (field) => {
  let returnField;
  switch (field.type) {
    case 'string':
      returnField = Joi.string().trim();
      break;
    case 'number':
      returnField = Joi.number();
      break;
    case 'boolean':
      returnField = Joi.boolean();
      break;
    case 'date':
      returnField = Joi.date();
      break;
    case 'datetime':
      console.log('got a datetime field');
      returnField = Joi.date();
      break;
    default:
      console.log('Not sure what this field is ', field);
      throw new Error('Unrecognized field type in build field', field);
  }

  if (field.type === 'number') {
    if (field.lower) {
      returnField = returnField.greater(field.lower);
    }
    if (field.upper) returnField = returnField.less(field.upper);
  }
  if (field.validValues) {
    field.validValues.forEach((item) => {
      returnField = returnField.valid(item.trim()).insensitive();
    });
  }

  if (field.valueRequired === false && !field.validValues) {
    returnField = returnField.allow('').allow(null);
  }
  return returnField.optional();
};

const buildValidationFields = (fields) => {
  const returnFields = [];

  fields.forEach((item) => {
    const newItem = { ...item };

    newItem.altNames = item.altNames.toLocaleLowerCase().split(',');
    // trim the values
    const tmp = [];
    newItem.altNames.forEach((a) => tmp.push(a.trim()));
    newItem.altNames = tmp;
    if (item.validValues) {
      newItem.validValues = newItem.validValues === null ? undefined : item.validValues.split(',');
    }
    returnFields.push(newItem);
  });
  return returnFields;
};

const getSchemaFromData = (data, fields) => {
  const firstRow = data[0];

  const joiItems = {};
  const fieldstemp = fields.filter((fi) => Array.isArray(fi.altNames));
  console.log(fieldstemp);
  for (const fieldName in firstRow) {
    // TODO :: Check to see AltNames is undefined or not... causing issues!

    const foundField = fieldstemp.find(
      (f) => f.altNames.indexOf(fieldName.toLocaleLowerCase()) > -1
    );
    if (fieldName.toLocaleLowerCase() === 'time') {
      console.log('This is the field for time', foundField);
    }
    if (foundField) {
      joiItems[fieldName] = buildJoiField(foundField);
    } else {
      joiItems[fieldName] = Joi.string()
        .allow(null)
        .allow('');
    }
  }

  const returnSchema = Joi.array().items(Joi.object(joiItems));

  return returnSchema;
};

// Generic validation function
const validateData = (arrObj, dataSchema) => {
  const validationOptions = { abortEarly: false };
  const results = dataSchema.validate(arrObj, validationOptions);
  console.log('Returning results', results);
  return results;
};

export { validateData, getSchemaFromData, buildValidationFields };
