import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography
} from '@material-ui/core';

import React from 'react';
import { useSelector } from 'react-redux';
import { Layers } from '@material-ui/icons';
import HasRole from 'src/views/auth/HasRole';
import MapToolBar from './MapToolBar';
import LayerControlItem from './LayerControlItem';

// A simple control to turn layers on and off.
const LayerControl = () => {
  const mapConfig = useSelector((state) => state.mapConfig);

  return (
    <div>
      {mapConfig.layers?.length > 0 ? (
        <HasRole requiredRole="GIS-EDITOR">
          <Accordion>
            <AccordionSummary>
              <Layers />
              <Typography>Layers</Typography>
            </AccordionSummary>
            <AccordionDetails width="100%">
              <Grid container>
                <Grid item xs={12}>
                  <MapToolBar />
                </Grid>
                {mapConfig?.layers?.map((layer) => (
                  <LayerControlItem item={layer} key={layer.file} />
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </HasRole>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LayerControl;
