/* eslint-disable no-debugger */
import {
  Box,
  Chip,
  colors,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { ResponsiveCirclePacking } from '@nivo/circle-packing';
import { ResponsiveTreeMap } from '@nivo/treemap';
import axios from 'axios';
import React, { useEffect } from 'react';
import { BiMapPin } from 'react-icons/bi';
import { FaBinoculars } from 'react-icons/fa';
import { FiEdit3 } from 'react-icons/fi';
import {
  GiAmmoniteFossil,
  GiEgyptianBird,
  GiFox,
  GiNestBirds
} from 'react-icons/gi';
import { GoCircleSlash } from 'react-icons/go';
import { GrDocumentPdf, GrDocumentText } from 'react-icons/gr';
import { API_BASE_URL, Queries } from 'src/config';
import GridPro from 'src/controls/GridPro';
import Communication from 'src/views/communication';
import Map from 'src/views/map';
import { useSelector } from 'react-redux';
import SimpleMetric from '../reports/DashboardView/SimpleMetric';
import Fossils from './fossils';
import DataGridMapInteraction from '../reports/DashboardView/DataGridPro/DataGridMapInteraction';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function AltDashboard(props) {
  const [tpValue, setTpValue] = React.useState(0);
  const [paleoData, setPaleoData] = React.useState();
  const [rptData, setRptData] = React.useState();
  const [mapCoords, setMapCoords] = React.useState();
  const [mapData, setMapData] = React.useState();
  const [comData, setComData] = React.useState();
  const [selRep, setSelRept] = React.useState();
  const [srData, setSrData] = React.useState();
  const [srDataRaw, setSrDataRaw] = React.useState();
  const [srSubData, setSrSubData] = React.useState();
  const [specFilter, setSpecFilter] = React.useState();
  const [reportCount, setReportCount] = React.useState(0);

  const mapDataRx = useSelector((state) => state.mapConfig);

  const handleChange = (event, newValue) => {
    setTpValue(newValue);
  };

  const getSRData = async () => {
    const sr = await axios.get(API_BASE_URL + Queries('ALT_SR'));
    return sr;
  };
  const getComs = async () => {
    const coms = await axios.get(API_BASE_URL + Queries('ALT_Coms'));
    return coms;
  };

  const getData = async () => {
    const resp = await axios.get(API_BASE_URL + Queries('ALT_Paleo'));
    return resp.data;
  };

  useEffect(() => {
    getData().then((resp) => {
      setPaleoData(resp);
    });
    getSRData().then((srData) => {
      // Count species
      const retData = [];
      const sbData = [];
      srData.data.rows.forEach((item, ndx) => {
        item.tip = (
          <>
            <Chip
              style={{ color: 'orange', backgroundColor: 'whitesmoke' }}
              label={`Species: ${item.sp_of_interest}`}
            />
            <br />
            <Chip
              style={{ color: 'orange', backgroundColor: 'whitesmoke' }}
              label={`Habitat: ${item.habitat_type}`}
            />
          </>
        );
        const recItem = retData.find(
          (rec) => rec.label === item.sp_of_interest
        );
        if (!recItem) {
          retData.push({
            label: item.sp_of_interest,
            id: item.sp_of_interest,
            value: 1
          });
        } else {
          recItem.value += 1;
        }

        // for sunburst ->
        const spArry = item.sp_of_interest.split(',');
        let curLevel = sbData.find((rec) => rec.name === spArry[0]);
        if (!curLevel) {
          const newItem = {
            name: spArry[0],
            children: [],
            val: 0,
            color: `hsl(${(ndx + 1) * 30}, 70%, 50%)`
          };
          sbData.push(newItem);
          curLevel = newItem;
        }
        curLevel.val += 1;
        spArry.forEach((arrItem, ndx) => {
          if (ndx !== 0) {
            let tmp = curLevel.children
              && curLevel.children.find((rec) => rec.name === arrItem);
            if (!tmp) {
              const newItem = {
                name: arrItem,
                val: 0,
                color: 'hsl(31, 70%, 50%)'
              };

              if (!curLevel.children) curLevel.children = [];
              curLevel.children.push(newItem);
              tmp = newItem;
            }
            tmp.val += 1;
            curLevel = tmp;
          }
        });
      });

      const retChartData = { name: 'Sensitive Resources', children: sbData };
      setSrDataRaw(srData);
      setSrData(retChartData);
    });

    getRptData().then((resp) => {
      resp.rows.forEach((item) => {
        item.refId1 = item.boringId;
        item.refId2 = item.boringId;
      });
      setRptData(resp);
      setReportCount(resp.rows.length);

      setMapCoords({
        lng: parseFloat(resp.rows[0]._longitude),
        lat: parseFloat(resp.rows[0]._latitude)
      });
    });
    getComs().then((data) => {
      setComData(data);
    });
  }, []);

  const recursivePush = (obj, arr) => {
    obj.children
      && obj.children.forEach((a) => {
        arr.push(a);
        recursivePush(a, arr);
      });

    return arr;
  };

  const chrtClick = (evt) => {
    const path = evt.pathComponents ? evt.pathComponents : evt.path.reverse();
    //   evt.path = path.reverse();
    if (srSubData) {
      setSpecFilter(undefined);
      // just reset->
      setSrSubData(undefined);

      return;
    }
    let data = srSubData || srData;
    path.forEach((item, ndx) => {
      if (ndx !== 0) {
        data = data.children.find((i) => i.name === item);
      }
    });
    let mapDataRows = [];
    mapDataRows = recursivePush(data, mapDataRows);
    const filter = path[path.length - 1];
    setSpecFilter(filter);
    const tmp = srDataRaw.data.rows.filter(
      (a) => a.sp_of_interest.indexOf(filter) > -1
    );
    tmp.forEach((item) => {
      item.color = evt.color;
    });
    setMapData({ rows: tmp });
    setSrSubData(data.children ? data : srData);
  };

  const srCircles = () => (
    <div style={{ minHeight: 200, height: '35vh' }}>
      <ResponsiveCirclePacking
        data={srSubData || srData}
        margin={{
          top: 10,
          right: 10,
          bottom: 10,
          left: 10
        }}
        id="name"
        value="val"
        colors={{ scheme: 'nivo' }}
        childColor={{ from: 'color', modifiers: [['brighter', 0.4]] }}
        padding={4}
        enableLabels
        labelsFilter={function (e) {
          return e.node.depth === 2;
        }}
        labelsSkipRadius={10}
        labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
      />
    </div>
  );
  const srGrid = () => {
    const getCols = () => {
      const retCols = [
        {
          field: 'sp_of_interest1',
          sortable: false,
          disableColumnMenu: true,
          width: 60,
          headerName: 'Class',
          renderCell: (para) => {
            if (para.value.toString().indexOf(',') === -1) return para.value;
            const nm = para.value.split(',');
            if (nm[0].toLowerCase() === 'mammals') {
              return <GiFox style={{ fontSize: 20 }} />;
            }
            return <GiEgyptianBird style={{ fontSize: 20 }} />;
          }
        },
        {
          field: 'sp_of_interest',
          headerName: 'Species',
          width: 150,
          renderCell: (para) => {
            if (para.value.toString().indexOf(',') === -1) return para.value;
            const nm = para.value.split(',');
            return nm[nm.length - 1];
          }
        },
        { field: 'habitat_type', headerName: 'Type', width: 145 },
        {
          field: 'date',
          headerName: 'Date',
          type: 'date',
          width: 100,
          valueGetter: (params) => new Date(params.row.date),
          renderCell: (arg) => new Date(arg.value).toLocaleDateString()
        }
      ];
      srDataRaw.data.fields.forEach((item) => {
        const foundItem = retCols.find((a) => a.field === item.name);
        if (!foundItem) {
          retCols.push({
            field: item.name,
            headerName: item.name,
            width: 150,
            hide: true
          });
        } else {
          // Nothing -- for debug only
        }
      });

      return retCols;
    };
    const _data = () => {
      let retData = srDataRaw.data.rows;
      if (specFilter) {
        retData = srDataRaw.data.rows.filter(
          (a) => a.sp_of_interest.indexOf(specFilter) > -1
        );
      }
      return retData;
    };
    return (
      <GridPro
        style={{ height: '40vh' }}
        MapSub={setMapDataCB}
        density="compact"
        Selected={manageSelItem}
        columns={getCols()}
        rows={_data()}
      />
    );
  };

  const srTreeMap = () => (
    <div style={{ minHeight: 200, height: '25vh' }}>
      <ResponsiveTreeMap
        data={srSubData || srData}
        onClick={chrtClick}
        identity="name"
        label={function (e) {
          return `${e.id} (${e.formattedValue})`;
        }}
        value="val"
        valueFormat=".00s"
        margin={{
          top: 10,
          right: 10,
          bottom: 10,
          left: 10
        }}
        labelSkipSize={5}
        labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        parentLabelTextColor={{ from: 'color', modifiers: [['darker', 3]] }}
        borderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
      />
    </div>
  );

  const manageSelItem = (item) => {
    setSelRept(item);
  };
  const setMapDataCB = async (data) => {
    data.rows.forEach((item) => {
      item.tip = (
        <>
          <Chip
            variant="default"
            style={{
              color: item.discipline === 'Biological' ? 'green' : 'orange',
              backgroundColor: 'whitesmoke'
            }}
            label={item.boring_label}
          />
          <br />
          {' '}
          <Chip
            variant="default"
            style={{
              color: item.discipline === 'Biological' ? 'green' : 'orange',
              backgroundColor: 'whitesmoke'
            }}
            label={
              item.relocate
                ? `Relocated by ${item.distance_from_original_location_feet}' `
                : 'No relocation'
            }
          />
          <br />
          <Chip
            variant="default"
            style={{
              color: item.discipline === 'Biological' ? 'green' : 'orange',
              backgroundColor: 'whitesmoke'
            }}
            label={`Terminal Depth: ${item.terminal_depth_feet}'`}
          />
        </>
      );
      item.color = item.discipline === 'Biological'
        ? colors.green[500]
        : colors.orange[500];
    });
    setMapData(data);
  };
  const getRptData = async () => {
    const rep = await axios.get(API_BASE_URL + Queries('ALT_Main'));

    return rep.data;
  };

  const reportsGrid = () => (
    <DataGridMapInteraction
      hideMap
      noCardCanvas
      //   pageSize={15}
      name="Reports"
      chartOptions={{
        indexBy: 'field_person',
        title: 'Report count by person',
        groupOptions: [
          { value: 'field_person', label: 'Field Person' },
          { value: 'discipline', label: 'Discipline' }
        ]
      }}
      // MapSub={setMapDataCB}
      // density="compact"
      // Selected={manageSelItem}
      // style={{ height: '65vh', display: 'flex' }}
      columnData={[
        {
          headerName: 'Actions',
          field: 'boringId',
          width: 100,
          sortable: false,
          renderCell: (para) => (
            <>
              <IconButton
                onClick={() => window.open(
                  `https://web.fulcrumapp.com/records/${para.value}`
                )}
                style={{ padding: 1, fontSize: 16 }}
              >
                <FiEdit3 />
              </IconButton>
              <IconButton
                onClick={() => (window.location.href = `/app/reports/dashboard/detail/${para.value}`)}
                style={{ padding: 1, fontSize: 14 }}
              >
                <GrDocumentText style={{ color: '#bd46ff' }} />
              </IconButton>
              <IconButton
                onClick={() => window.open(
                  `https://web.fulcrumapp.com/reports/generate?report%5Brecord_id%5D=${para.value}`
                )}
                style={{ padding: 1, fontSize: 14 }}
              >
                <GrDocumentPdf style={{ color: '#bd464c' }} />
              </IconButton>
            </>
          )
        },
        {
          field: 'date',
          headerName: 'Date',
          width: 150,
          type: 'date',
          valueGetter: (params) => new Date(params.row.date),
          renderCell: (arg) => new Date(arg.value).toLocaleDateString()
        },
        {
          width: 200,
          field: 'field_person',
          headerName: 'Field Person',
          hidden: true
        },
        { field: 'boring_label', headerName: 'Boring', width: 175 },
        {
          field: 'relocate',
          headerName: 'Relocated?',
          width: 125,
          renderCell: (para) => (
            <Chip
              variant="outlined"
              style={{
                color: para.value === 'yes' ? 'green' : 'grey',
                borderColor: para.value === 'yes' ? 'green' : 'grey'
              }}
              label={para.value ? para.value.toUpperCase() : 'NO'}
            />
          )
        },
        {
          field: 'discipline',
          headerName: 'Discipline',
          width: 130,
          renderCell: (para) => (
            <Chip
              variant="outlined"
              style={{
                color: para.value === 'Biological' ? 'green' : 'orange'
              }}
              label={para.value}
            />
          )
        }
      ]}
      dataGridOptions={{ filterModel: { items: [] } }}
      data={rptData?.rows}
    />
  );

  return (
    <Paper elevation={5} style={{ height: '75' }}>
      <Grid spacing={2} container>
        <Grid item lrg={12} xs={12}>
          <Grid container>
            <Grid item xs={12} md={3} xl={3}>
              <SimpleMetric
                backgroundColor="transparent"
                title="Total Fossils Observed"
                icon={<GiAmmoniteFossil fontSize={35} />}
                sourceQuery="SELECT COUNT(*) from [Subsidence Geotech Explorations!1000!fossil_collection_log]"
              />
            </Grid>

            <Grid item xs={12} md={3} xl={3}>
              <SimpleMetric
                backgroundColor="transparent"
                icon={<GiNestBirds fontSize={35} />}
                additional={srData && srCircles()}
                title="Total Sensitive Resources Recorded"
                sourceQuery="SELECT COUNT(*) from [Subsidence Geotech Explorations!1000!record_sensitive_resources]"
              />
            </Grid>

            <Grid item xs={12} md={2} xl={2}>
              <SimpleMetric
                backgroundColor="transparent"
                icon={<BiMapPin fontSize={35} />}
                title="Borings Relocated"
                sourceQuery={
                  "SELECT COUNT(*) from vwGeotechReports where relocate='yes'"
                }
              />
            </Grid>

            <Grid item xs={12} md={2} xl={2}>
              <SimpleMetric
                backgroundColor="transparent"
                icon={<FaBinoculars fontSize={35} />}
                title="Reports Created"
                value={reportCount}
              />
            </Grid>

            <Grid item xs={12} md={2} xl={2}>
              <SimpleMetric
                backgroundColor="transparent"
                icon={<GoCircleSlash fontSize={35} />}
                title="Concerns"
                sourceQuery="SELECT COUNT(*) from [Subsidence Geotech Explorations!1000!log_concern]"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7} lrg={7} style={{ height: '75vh' }}>
          <Tabs value={tpValue} onChange={handleChange}>
            <Tab label="All Reports" />
            <Tab label="Sensitive Resources" />
            <Tab label="Fossils" />
            <Tab label="Communications" />
            <Tab label="Concerns" />
          </Tabs>

          <TabPanel value={tpValue} index={0}>
            {rptData && reportsGrid()}
          </TabPanel>
          <TabPanel value={tpValue} index={1}>
            {srData && (
              <Grid container>
                <Grid
                  lg={12}
                  sm={12}
                  style={{ minBlockSize: 200, height: '100%' }}
                >
                  {srGrid()}
                </Grid>
                <Grid lg={12} sm={12}>
                  {srTreeMap()}
                </Grid>
              </Grid>
            )}
          </TabPanel>
          <TabPanel value={tpValue} index={2}>
            <Fossils
              MapSub={setMapDataCB}
              density="compact"
              Selected={manageSelItem}
              style={{ height: '65vh', display: 'flex' }}
            />
          </TabPanel>
          <TabPanel value={tpValue} index={3}>
            {comData && (
              <>
                <Communication coms={comData} />
              </>
            )}
          </TabPanel>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          lrg={5}
          style={{ height: '70vh', padding: '0px' }}
        >
          {mapCoords && (
            <Map
              style={{ height: '70vh' }}
              height="70vh"
              selItem={mapDataRx.selectedItem}
              SubData={mapDataRx.displayItems}
              lat={mapCoords.lat}
              lng={mapCoords.lng}
            />
          )}
          {' '}
        </Grid>

        <Grid item xs={12} md={7} lrg={7} />
        <Grid item xs={12} md={5} lrg={5}>
          {' '}
        </Grid>
      </Grid>
    </Paper>
  );
}
