import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ReusableLink = ({ pathname, icon, openInNew }) => (
  <>
    {openInNew ? (
      <Link
        to={{
          pathname
        }}
        target="_blank"
      >
        {icon}
      </Link>
    ) : (
      <Link
        to={{
          pathname
        }}
      >
        {icon}
      </Link>
    )}
  </>
);
ReusableLink.propTypes = {
  pathname: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  openInNew: PropTypes.bool
};

export default ReusableLink;
