/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import useSettings from 'src/hooks/useSettings';
import DashboardLayout from 'src/layouts/DashboardLayout';

import MainLayout from 'src/layouts/MainLayout';
import { hasRole } from 'src/lib';
import HomeView from 'src/views/pages/HomeView';
import { ROLES_HOME } from './config';
import { useKeycloak } from '@react-keycloak/web';

function Routes() {
  const { settings } = useSettings();
  const projectSettings = useSelector(state => state.projectSettings);
  const { keycloak } = useKeycloak();

  const routesConfig = (consumer, settings, projectSettings) => {
    let homePage = undefined;
    let homeImport = 'src/views/reports/DashboardView/index1';
    if (projectSettings?.homeImport) homeImport = projectSettings?.homeImport;
    ROLES_HOME.map(item => {
      var hr = hasRole(consumer, settings.project.toLowerCase(), item.role);
      if (hr) {
        if (item.component) {
          homePage = item.component;
        } else {
          var ret = lazy(() => import(`${item.homePage}`));
          if (item.redir) {
            ret = <Redirect to={item.redir} />;
          }
          homePage = ret;
        }
      }
    });

    if (!homePage) {
      homePage = lazy(() => import('src/views/auth/NoAccess'));
    }

    return [
      {
        path: '/app/analytics',
        header: true,
        layout: DashboardLayout,
        routes: [
          {
            exact: false,
            path: '/app/analytics/waterquality/:mode/:item',
            component: lazy(() => import('src/views/waterquality'))
          },
          {
            exact: false,
            path: '/app/analytics/waterquality/:mode',
            component: lazy(() => import('src/views/waterquality'))
          }
        ]
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/no-access',
        component: lazy(() => import('src/views/auth/NoAccess'))
      },
      {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/pages/Error404View'))
      },
      {
        exact: true,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView'))
      },
      {
        exact: true,
        path: '/login-unprotected',
        component: lazy(() => import('src/views/auth/LoginView'))
      },
      {
        exact: true,
        path: '/register',
        component: lazy(() => import('src/views/auth/RegisterView'))
      },
      {
        exact: true,
        path: '/register-unprotected',
        component: lazy(() => import('src/views/auth/RegisterView'))
      },
      {
        path: '/app',
        header: true,
        layout: DashboardLayout,
        routes: [
          {
            header: true,
            title: 'Dashboard',
            exact: true,
            path: '/app',
            component: () => <Redirect to="/app/reports/dashboard" />
          },
          {
            header: false,
            title: 'Account',
            exact: true,
            render: {
              title: 'Account'
            },
            path: '/app/account',
            component: lazy(() => import('src/views/pages/AccountView'))
          },
          {
            header: true,
            title: 'Dashboard',
            exact: true,
            path: '/app/reports/dashboard',
            component: homePage
          },
          {
            header: true,
            title: 'Advanced Search',
            exact: true,
            path: '/app/reports/advanced-search',
            component: lazy(() =>
              import('src/views/AdvancedSearch/GlobalSearch')
            )
          },
          {
            header: false,
            title: 'Dashboard Details',
            exact: false,
            path: '/app/reports/dashboard/detail/:item',
            component: lazy(() => import('src/views/altDashboard/detail'))
          },
          {
            header: false,
            title: 'Dashboard',
            exact: true,
            path: '/app/reports/dashboard-alternative',
            component: lazy(() =>
              import('src/views/reports/DashboardAlternativeView')
            )
          },
          {
            header: false,
            title: 'Daily Reports',
            exact: true,
            path: '/app/reports',
            component: () => <Redirect to="/app/reports/dashboard" />
          },
          {
            header: false,
            title: 'Daily Reports',
            exact: true,
            path: '/app/DailyReports/:id',
            component: lazy(() => import('src/views/DailyReports'))
          },
          {
            header: true,
            title: 'Observation Reports',
            exact: true,
            path: '/app/Observation/:id',
            component: lazy(() => import('src/views/observations'))
          },

          {
            component: () => <Redirect to="/404" />
          }
        ]
      },

      {
        path: '*',
        layout: MainLayout,
        routes: [
          {
            exact: true,
            path: '/app',
            component: () => <Redirect to="/app/reports/dashboard" />
          },
          {
            exact: true,
            path: '/home',
            component: HomeView
          },
          {
            exact: true,
            path: '/pricing',
            component: lazy(() => import('src/views/pages/PricingView'))
          },
          {
            component: () => <Redirect to="/404" />
          }
        ]
      }
    ];
  };

  const renderRoutes = routes =>
    routes ? (
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component || Fragment;
            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={props =>
                  keycloak?.authenticated ? (
                    <Guard>
                      <Layout>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <Component {...props} title={route.title} />
                        )}
                      </Layout>
                    </Guard>
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/no-access',
                        state: { from: props.location }
                      }}
                    />
                  )
                }
              />
            );
          })}
        </Switch>
      </Suspense>
    ) : null;

  return renderRoutes(
    routesConfig(keycloak, settings),
    settings,
    projectSettings
  );
}

export default Routes;
