import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import LinkIcon from '@mui/icons-material/Link';
import ReusableLink from 'src/components/ReusableLink';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import Label from '../components/Label';

export const renderBoolean = (params) => {
  if (!params.value) {
    return '-';
  }
  return params.value === 'yes' ? (
    <DoneIcon color="success" />
  ) : (
    <BlockIcon color="warning" />
  );
};

export const renderTime = (params) => {
  if (!params.value) {
    return '-';
  }
  return params.value.slice(0, 5);
};

export const renderDate = (params) => moment(params.value).utc().format('M/D/YYYY');

export const renderDateTime = (params) => {
  const time = params.row.time.split(':');
  const hour = parseInt(time[0], 10);
  const minute = parseInt(time[1], 10);
  const date = moment(params.row.date)
    .utc()
    .hour(hour)
    .minute(minute)
    .format('M/D/YYYY, HH:mm');
  return date;
};

export const downloadReport = (arg) => {
  if (!arg.value) return '-';

  return (
    <ReusableLink
      pathname={arg.value}
      icon={<PictureAsPdf fontSize="small" color="primary" />}
      openInNew
    />
  );
};

export const renderLink = (pathName) => (
  <ReusableLink
    pathname={pathName}
    icon={<LinkIcon fontSize="small" color="primary" />}
  />
);

export const renderWithToolTip = (arg) => {
  if (arg.value) {
    return (
      <Tooltip
        disableFocusListener
        title={(
          <Typography color="inherit" variant="body2">
            {arg.value}
          </Typography>
        )}
        placement="top-start"
      >
        <span className="table-cell-trucate">{arg.value}</span>
      </Tooltip>
    );
  }
  return arg.value;
};

export const renderLabel = (params) => {
  switch (params.value) {
    case 'Closed':
      return <Label color="success">{params.value}</Label>;
    case 'New':
      return <Label color="primary">{params.value}</Label>;
    case 'On-going':
      return <Label color="primary">{params.value}</Label>;
    case 'Concern':
      return <Label color="warning">{params.value}</Label>;
    case 'Untracked':
      return <Label color="error">{params.value}</Label>;
    case 'No Concern':
      return <Label color="success">{params.value}</Label>;
    case 'Biological':
      return <Label color="success">{params.value}</Label>;
    default:
      return <Label color="secondary">{params.value}</Label>;
  }
};
