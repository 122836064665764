import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Typography
} from '@material-ui/core';
import {
  CloudDoneOutlined,
  DeleteOutlined,
  ErrorOutline
} from '@material-ui/icons';
import Client from 'esa-gcclient';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';

import {
  dataGridColsFromImport,
  isCellErrored,
  isRowErrored
} from 'src/utils/dataGridColsFromImport';
import DataGrid from 'src/views/waterquality/components/wqGrid';
import CreateDeployment from '../../views/waterquality/controls/createDeployment';
import { clearImports } from 'src/reducers/importReducer';
import { createDeploymentRef } from 'src/utils/waterQuality';
import { calcDistance } from 'esa-gcclient/geo';

const prepDataForGrid = (data, errors) => {
  let iRowNumb = 0;
  let retData = [];

  data.forEach(item => {
    let errored = isRowErrored(errors, iRowNumb);
    let newItem = { id: iRowNumb, errored: errored, ...item };
    if (item.Station && item.Latitude & item.Longitude) {
      //get station
    }
    if (item.Station && item.Date) {
      // console.log(item)
      newItem.Reference = createDeploymentRef(
        { stationName: item.Station, datetime: item.Date },
        'W'
      );
    }
    retData.push(newItem);

    iRowNumb++;
  });

  return retData;
};

export const ImportItem = itemData => {
  const errors = itemData?.itemData?.data?.error?.details;
  const [preppedData, setPrepped] = useState(
    prepDataForGrid(itemData?.itemData?.data?.value, errors)
  );
  const fields = useSelector(state => state.imports.validationFields);
  const [disableCommit, setDisableCommit] = React.useState(false);
  const [showErrDiag, setShowErrDiag] = useState(false);
  const columnData = dataGridColsFromImport(itemData, fields);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [deployment, setDeployment] = React.useState();
  const [reqDep, setReqDep] = React.useState(false);
  const [saveResult, setSaveResult] = React.useState();
  useEffect(() => {
    if (preppedData.length === 0) return;
    //Check to see if prepped data has station, lat and lng

    const checkForStationData = async () => {
      setLoading(true);
      let newSet = preppedData;
      let lineZero = preppedData[0];
      if (
        lineZero.Station !== undefined &&
        lineZero.Latitude !== undefined &&
        lineZero.Longitude !== undefined
      ) {
        newSet = [];
        for (const ndx in preppedData) {
          let item = preppedData[ndx];
          let res = await Client.getData(
            'station',
            `stationName='${item.Station}'`
          );

          if (res.data.length !== 0) {
            item.stationLat = res.data[0].stationLat;
            item.stationLng = res.data[0].stationLng;
            item.deployOffset = Math.round(
              calcDistance(
                item.stationLat,
                item.stationLng,
                item.Latitude,
                item.Longitude
              ) * 1000
            );
          }
          newSet.push(item);
        }
        setLoading(false);
        return newSet;
      }
    };
    checkForStationData().then(res => {
      if (
        !preppedData[0].Reference &&
        !preppedData[0].Station &&
        columnData.hasData === true
      ) {
        console.log('Line item', preppedData[0].Reference);
        setReqDep(true);
      } else {
        console.log('setting dep false');
        setReqDep(false);
      }
      if (res) setPrepped(res);
    });
  }, []);
  const getLoadingDisplay = () => {
    return <div>Doing something!</div>;
  };
  const handleDiscard = () => {
    dispatch(clearImports());
  };

  const savedDialog = (
    <Dialog open={saveResult}>
      <Paper elevation={8} style={{ padding: 10, borderRadius: 8 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3">Save Result</Typography>
          </Grid>
          <Grid item xs={6}>
            Result
          </Grid>
          <Grid item xs={6}>
            {saveResult?.data?.result?.rowsAffected} results commited
          </Grid>
          <Grid item xs={12}>
            <div style={{ width: '100%', textAlign: 'right' }}>
              <Button
                onClick={() => {
                  setSaveResult(undefined);
                  handleDiscard();
                }}
              >
                Ok
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
  const acceptCreatedDep = dep => {
    console.log('Got this from create deployment', dep);
    dep = dep[0];
    console.log('deployment created');
    setDeployment(dep);
    setReqDep(false);
    setLoading(false);
    return;
  };
  const isErrored = () => {
    if (!preppedData) return true;
    if (preppedData.find(item => item.errored === 'Yes')) return true;
    return false;
  };

  const commitProfileData = recordSource => {
    let objSave = { rSourceId: recordSource, records: preppedData };
  };
  const doCommit = async () => {
    console.log('Creating Record source ');

    //deployment file
    if (preppedData[0].Station) {
      console.log('saving profile data..');
      setLoading(true);
      let result = await Client.saveData({
        tableName: '*profiles',
        preppedData
      });
      /*        
      if(result.status ===200)
        console.log(result);
      if(result.status!==200)
        console.log('data could not be saved') */
      setLoading(false);
      handleDiscard(); //just get rid of the dataset.
      return;
    } else {
      //Append deployment id ->
    }
    //Create the file record
    let recSource = {
      tableName: 'recordSource',
      rSourceTypeId: 1, //1= file, 2=API
      rSourceFilePath: itemData?.itemData?.file?.name,
      userName: 'notset',
      created: new Date().toLocaleDateString()
    };
    let result = await Client.saveData(recSource);

    let sourceId = result.data.recordset[0].id;

    let dataCopy = { ...preppedData };
    setLoading(true);
    for (let res in dataCopy) {
      dataCopy[res].sourceId = sourceId;
      dataCopy[res].deploymentId = deployment.deploymentId;
      //remove id and errored fields
      dataCopy[res].id = undefined;
      dataCopy[res].errored = undefined;
    }
    
    Client.saveData({ tableName: '**analytical', records: dataCopy }).then(
      result => {
        setSaveResult(result);
      }
    );

    setLoading(false);
    //Now find matching deployment
    //get unique deployments.
    /*   let depRefs = [];

        preppedData.forEach(rowItem => {
          if (depRefs.indexOf(rowItem.Reference) < 0)
            depRefs.push(rowItem.Reference);
        });

        console.log('DepRefs', depRefs);
        let searchstr = '';
        depRefs.forEach(ref => {
          searchstr += `'${ref}',`;
        });
        console.log(searchstr, 'search string');
        searchstr = searchstr.substring(0, searchstr.length - 1);
        console.log('searchstr', searchstr);
        Client.getData('deployment', `ref in (${searchstr})`).then(
          depResult => {
            console.log('Dep Result=>', depResult);
            if (depResult.status === 200) {
              if (depResult.data.length !== depRefs.length) {
                console.log(
                  'All deployments not in the database. This cannot be commited.'
                );
              } else {
                //continue with commit
              }
            } else {
              alert('Could not save data (1)');
            }
          }
        );
      } else {
        alert('Your data could not be saved.');
      } */
  };
  const handleCommit = () => {
    if (isErrored()) {
      setShowErrDiag(true);
      return;
    }
    setDisableCommit(true);
    doCommit();
  };

  const DeploymentDiag = (
    <Dialog open={reqDep}>
      <CreateDeployment
        title={
          <Chip
            style={{ fontSize: 12 }}
            label={itemData?.itemData?.file?.name}
          />
        }
        createCallBack={acceptCreatedDep}
      />
    </Dialog>
  );
  const errDialog = (
    <Dialog open={showErrDiag}>
      <Paper
        elevation={5}
        style={{ borderRadius: 5, padding: 5 }}
        onClick={() => setShowErrDiag(false)}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
              Commit Errored File : {itemData?.itemData?.file?.name}?{' '}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16 }}>
              {' '}
              Your data appears to have failed preliminary validation. You can
              still submit this data however it is not recommended.
            </Typography>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button onClick={doCommit}>Commit</Button>
            <Button>Cancel</Button>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
  return (
    <Paper>
      {errDialog}
      {savedDialog}
      {reqDep && DeploymentDiag}
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <Typography style={{ fontSize: 24 }}>
                  {isErrored() === true && (
                    <ErrorOutline
                      style={{ fontSize: 32, color: 'red', margin: -6 }}
                    />
                  )}{' '}
                  {itemData?.itemData?.file?.name}
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              {columnData?.hasData === false && (
                <Typography>
                  {' '}
                  <ErrorOutline
                    style={{ fontSize: 32, color: 'red', margin: -6 }}
                  />
                  This file does not contain any data that I recognize.
                </Typography>
              )}
              {deployment && (
                <Typography>
                  This data will be bound to deployment :{' '}
                  <Chip label={deployment.ref} />
                </Typography>
              )}
              <Typography style={{ height: '50vh' }}>
                {preppedData ? (
                  <Box
                    sx={{
                      height: '45vh',
                      width: 1,
                      '& .goodCol': {
                        backgroundColor: 'rgba(138, 245, 39, 0.1)'
                      },
                      '& .badCol': {
                        backgroundColor: 'rgba(245, 39, 145, 0.1)'
                      }
                    }}
                  >
                    <DataGrid
                      height={'45vh'}
                      /*         getCellClassName={(params)=>{
                            let e = isCellErrored(params.row,params.field,errors)
                            console.log(e) 
                            if(e===true){
                            return 'error'
                            }
                        }} */
                      components={{
                        LoadingOverLay: getLoadingDisplay
                      }}
                      loading={!columnData.hasData || loading}
                      rows={preppedData}
                      columns={columnData.cols}
                      density={'compact'}
                    ></DataGrid>{' '}
                  </Box>
                ) : (
                  <div>No Data</div>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} lg={8} xl={0}>
          <Button style={{ backgroundColor: 'rgba(138, 245, 39, 0.1)' }}>
            Recognized Column
          </Button>
          <Button style={{ backgroundColor: 'rgba(245, 39, 145, 0.1)' }}>
            Unused Column
          </Button>
          <Button>Generated Column</Button>
          <Button style={{ borderRadius: 5, border: '2px red dotted' }}>
            Issue with Data
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          xl={2}
          style={{ textAlign: 'right', border: 4 }}
        >
          <IconButton style={{ fontSize: 14 }} onClick={handleDiscard}>
            <DeleteOutlined />
            Discard
          </IconButton>
          <IconButton
            style={{ fontSize: 14 }}
            disabled={disableCommit}
            onClick={handleCommit}
          >
            <CloudDoneOutlined />
            Commit to database
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ImportItem;
