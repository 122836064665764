/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Card } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import EachCommunication from './EachCommunication';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    flexGrow: 5,
    display: 'flex',
    margin: theme.spacing(1)
  }
}));

export default function Communication({ coms, hideSourceLink }) {
  const classes = useStyles();

  return (
    <>
      {coms && coms?.length > 0 ? (
        coms?.map((communicaton) => (
          <EachCommunication
            key={communicaton?.metaData?.id || communicaton?._record_id}
            item={communicaton}
            hideSourceLink={hideSourceLink}
          />
        ))
      ) : (
        <Card className={classes.root}>
          <HourglassEmptyIcon color="disabled" />
          <Typography variant="h6" color="textSecondary">
            There are no communicatons. Please check back later.
          </Typography>
        </Card>
      )}
    </>
  );
}

Communication.propTypes = {
  coms: PropTypes.arrayOf(PropTypes.object),
  hideSourceLink: PropTypes.bool
};
