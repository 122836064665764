import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Typography
} from '@material-ui/core';
import { Close, Fullscreen } from '@material-ui/icons';
import { ResponsivePie } from '@nivo/pie';
import { makeStyles } from '@material-ui/core/styles';
import useSettings from 'src/hooks/useSettings';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default function PieDataChart({ data, chartOptions }) {
  const { settings } = useSettings();
  const [ndxBy, setNdxBy] = useState(chartOptions.indexBy);
  const [modOpen, setModOpen] = useState(false);
  const [pieData, setPieData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const updatedData = [];
    let maxLabelLength = 10;
    if (ndxBy) {
      data.forEach(row => {
        if (updatedData.find(item => item.id === row[ndxBy])) {
          updatedData.find(item => item.id === row[ndxBy]).value += 1;
        } else {
          updatedData.push({
            value: 1,
            id: row[ndxBy],
            label: row[ndxBy]
          });
          if (
            row[ndxBy] &&
            row[ndxBy].length > maxLabelLength &&
            row[ndxBy].length < 45
          )
            maxLabelLength = row[ndxBy].length;
        }
      });
      setNdxBy(ndxBy);
      setPieData(updatedData);
    }
  }, [data, ndxBy]);

  if (pieData.length === 0) {
    return <Typography variant="h6">No trackable data to display.</Typography>;
  }

  const mainDisplay = (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <div
          style={{
            height: modOpen ? window.screen.height : 400,
            padding: modOpen ? 150 : 0,
            display: 'flex',
            backgroundColor: modOpen ? 'white' : ''
          }}
        >
          {modOpen ? (
            <IconButton onClick={() => setModOpen(!modOpen)}>
              <Close titleAccess="close chart" color="primary" />
            </IconButton>
          ) : (
            <></>
          )}
          <ResponsivePie
            data={pieData}
            margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
            fit
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor={
              modOpen || settings.theme === 'LIGHT' ? '#333333' : '#FFFFFF'
            }
            arcLinkLabelsThickness={2}
            arcLabelsSkipAngle={10}
            legends={
              false && [
                {
                  anchor: 'top-left',
                  direction: 'column',
                  justify: false,
                  translateX: -60,
                  translateY: 0,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemsSpacing: 0,
                  symbolSize: 20,
                  itemDirection: 'left-to-right'
                }
              ]
            }
          />
        </div>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            <Select onChange={e => setNdxBy(e.target.value)} value={ndxBy}>
              {chartOptions.groupOptions.map(a => (
                <MenuItem
                  value={a.value}
                  selected={ndxBy.toLowerCase() === a.value.toLowerCase()}
                >
                  {a.label}
                </MenuItem>
              ))}
            </Select>
          }
          action={
            <IconButton onClick={() => setModOpen(!modOpen)}>
              {modOpen ? (
                <Close titleAccess="close chart" />
              ) : (
                <Fullscreen titleAccess="open chart" />
              )}
            </IconButton>
          }
        />
        <CardContent>{mainDisplay}</CardContent>
      </Card>
      <Modal open={modOpen} className={classes.modal}>
        {mainDisplay}
      </Modal>
    </>
  );
}

PieDataChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  chartOptions: PropTypes.objectOf(PropTypes.object)
};
