import React, { useEffect, useState } from 'react';
import Client from 'esa-gcclient'
import { FormControl, Select ,InputLabel, MenuItem} from '@material-ui/core';



export default function DropDown(props){

    const [data,setData] = useState();

    useEffect(()=>{
        //console.log('Getting data')
        if(props.table){
            Client.getData(props.table,'',false).then(result=>{
            
                setData(result.data);
             
            })
        }

    },[props])


     return (
      <>
            {data &&
            
             <FormControl variant="standard" style={{ minWidth: 200, height:40 }}>
                 <InputLabel id={props.name + '__id'}>{props.name}</InputLabel>
                <Select

                    labelId={props.name + '__id'}
                    id={props.name + '__id__select'}
                    label={props.name}
                    {...props}
                >
                    {
                        data.map(item=>(
                            <MenuItem value={item}>{item[props.displayField]}</MenuItem>
                        ))
                    }

                </Select>

             </FormControl>
                
            
            
            }

         </>
     )
}