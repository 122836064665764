import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import {
  Breadcrumbs, Button, Grid, Link, Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/Search';
import { navConfig } from 'src/layouts/DashboardLayout/NavBar';
import useSettings from 'src/hooks/useSettings';
import HasRole from 'src/views/auth/HasRole';

function Header() {
  const [title, setTitle] = useState('');
  const location = useLocation();
  const history = useHistory();
  const [showMe, setShowMe] = useState(false);
  const { settings } = useSettings();

  const findTitle = (navItem) => {
    if (navItem.href === location.pathname) {
      setTitle(navItem.title);
      setShowMe(true);
      return navItem.title;
    }
    if (navItem.items) {
      navItem.items.forEach((subItem) => {
        const tempTitle = findTitle(subItem);
        if (tempTitle) return tempTitle;
      });
    }
  };
  useEffect(() => {
    setShowMe(false);
    navConfig().then((response) => {
      response.every((item) => {
        const _tmpTitle = findTitle(item);
        if (_tmpTitle) {
          setTitle(_tmpTitle);
          setShowMe(true);
        }
      });
    });
  }, [location.pathname]);

  return !showMe ? (
    <></>
  ) : (
    <Grid container spacing={3} justifyContent="space-between">
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label={title}
        >
          <Link
            variant="body1"
            color="inherit"
            to="/app"
            component={RouterLink}
          >
            {settings.project} Dashboard
          </Link>
          <Typography variant="body1" color="textPrimary">
            {title}
          </Typography>
        </Breadcrumbs>
        <Typography variant="h3" color="textPrimary">
          Field Data {title}
        </Typography>
      </Grid>
      {location.pathname === '/app/reports/advanced-search' ? (
        <></>
      ) : (
        <Grid item>
          <HasRole requiredRole="advancedSearch">
            <Button
              onClick={() => history.push('/app/reports/advanced-search')}
            >
              <SearchIcon titleAccess="Search" />
              &nbsp;Advanced Search
            </Button>
          </HasRole>
        </Grid>
      )}
    </Grid>
  );
}

export default Header;
