import { createSlice } from '@reduxjs/toolkit';

export const ImportsManager = createSlice({
  name: 'imports',
  initialState: {importFiles:[],validationFields:[]},
  reducers: {
    //Assumes that data is sent in object format including a key property {key:'some key',data:[grid data]}
    storeImport: (state, action) => {
      state.importFiles.push(action.payload);
    } ,
    storeValidationFields:(state, action) => {
      state.validationFields = action.payload;
    },
    clearImports : (state,action)=>{
      state.importFiles = [];
    },
    removeImport : (state,action)=>{

    }
  }
});

export const importsReducer = state => state.imports;

export const { storeImport,storeValidationFields,clearImports,removeImport} = ImportsManager.actions;
export default ImportsManager.reducer;
