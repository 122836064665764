import React, { useEffect, useState } from 'react';
import MapContainer from 'src/views/map/MapContainer';
import Pagination from '@mui/material/Pagination';
import {
  gridPageSelector,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
  gridPaginatedVisibleSortedGridRowEntriesSelector
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import { setDisplayItems } from 'src/reducers/mapLayerReducer';
import { useDispatch, useSelector } from 'react-redux';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import IconButton from '@mui/material/IconButton';

const MapPageFooter = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rows = useGridSelector(
    apiRef,
    gridPaginatedVisibleSortedGridRowEntriesSelector
  );
  const projectSettings = useSelector(
    (state) => state.projectSettings.settings
  );
  const dispatch = useDispatch();
  const [mapSize, setMapSize] = useState('33vh');

  useEffect(() => {
    let finalRows = [];
    rows.map((row) => finalRows.push(row.model));
    dispatch(setDisplayItems({ rows: finalRows }));
    finalRows = [];
  }, [rows, page, pageCount, dispatch]);

  if (!projectSettings) return <></>;
  return (
    <Box>
      <Box sx={{ marginTop: 2, marginBottom: 2, display: 'flex' }}>
        <MapContainer mapHeight={mapSize} />
      </Box>
      <Pagination
        sx={{
          float: 'right',
          paddingBottom: 2
        }}
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
      <ButtonGroup aria-label="map zoom controls">
        {mapSize === '33vh' ? (
          <IconButton
            color="primary"
            aria-label="zoom in"
            component="span"
            onClick={() => setMapSize('50vh')}
          >
            <ZoomInIcon />
          </IconButton>
        ) : (
          <IconButton
            color="primary"
            aria-label="zoom out"
            component="span"
            onClick={() => setMapSize('33vh')}
          >
            <ZoomOutIcon />
          </IconButton>
        )}
      </ButtonGroup>
    </Box>
  );
};

export default MapPageFooter;
