import { createSlice } from '@reduxjs/toolkit';

export const DashboardData = createSlice({
  name: 'dashboardData',
  initialState: {},
  reducers: {
    saveDashboardData: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
  }
});

export const dataGridData = state => state.gridData;

export const { saveDashboardData, setDownloadIds } = DashboardData.actions;
export default DashboardData.reducer;
