/* eslint-disable no-debugger */
import { GridLinkOperator } from '@mui/x-data-grid-pro';

const DEFAULT = {
  items: [
    {
      id: 1,
      columnField: '_status',
      operatorValue: 'contains',
      value: 'New'
    },
    {
      id: 2,
      columnField: '_status',
      operatorValue: 'contains',
      value: 'On Going'
    }
  ],
  linkOperator: GridLinkOperator.Or
};

const TrackableOptions = {
  filterModel: DEFAULT
};

export default TrackableOptions;

export const getTrackableOptions = (projectSettings) => {
  if (projectSettings?.settings?.trackableFilters) {
    const tmpModel = DEFAULT;
    tmpModel.items = projectSettings.settings.trackableFilters;

    return { filterModel: tmpModel };
  }
  return { filterModel: DEFAULT };
};
