import {
  Grid, Paper, Typography
} from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Client from 'esa-gcclient';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnaFileAttachements from 'src/controls/attachments/anaFileAttachments';
import { buildValidationFields } from 'src/controls/attachments/validation';
import Imports from 'src/controls/imports';
import { storeValidationFields } from 'src/reducers/importReducer';

import HasRole from 'src/views/auth/HasRole';

export default function ImportData() {
  const dispatch = useDispatch();

  const importItems = useSelector((state) => state.imports.importFiles);

  useEffect(() => {
    Client.getData('importFields').then((result) => {
      const validFields = buildValidationFields(result.data);

      console.log('This is being pushed to redux', validFields);
      dispatch(storeValidationFields(validFields));
    });
  }, []);
  return (
    <Paper>
      <HasRole
        requiredRole="data-import"
        ErrorMessage={(
          <Typography variant="h2">
            Sorry, you do not have access to this module
          </Typography>
        )}
      >
        <Grid container>
          <Grid item md={12} />

          <Grid item md={12}>
            <Accordion expanded={importItems.length === 0}>
              <AccordionSummary>
                <Typography variant="h3">Import Sensor Data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AnaFileAttachements
                  bindGuid="-test-data"
                  title="Upload Analytical Data"
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item md={12}>
            <div>
              <Imports />
            </div>
          </Grid>
        </Grid>
      </HasRole>
    </Paper>
  );
}
