

export const metricDisplay =  [
    {
        displayName : 'max pH',
        queryString : ''
    },
    {
        displayName : 'max Turbidity',
        queryString : ''
    },  
    {
        displayName : 'max Salinity',
        queryString : ''
    },
    {
        displayName : 'max Dissolved O2',
        queryString : ''
    },

]