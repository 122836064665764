import {
  Button,
  Grid,
  Input,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';

import Client from 'esa-gcclient';
import React, { useEffect, useState } from 'react';
import { FaCheck, FaExclamationCircle } from 'react-icons/fa';
import { calcDistance } from 'esa-gcclient/geo';
import { createDeploymentRef } from 'src/utils/waterQuality';
import { RefreshSharp } from '@material-ui/icons';

const badDist = 100;
const CreateDeployment = props => {
  const [dep, setDep] = useState({});
  const [depRef,setDepRef] = useState('');
  //const [validRef, setValidRef] = useState(true);
  const [validSite, setValidSite] = useState(false);
  const [station, setStation] = useState();
  const [eqList, setEqList] = useState([]);
  const [equip, setEquip] = useState();
  const [posList, setPosList] = useState();
  const [validEq, setValidEq] = useState(false);
  const [dist, setDist] = useState(0);
 // const [tempDep, setTempDef] = useState({});
  const updateDep = (prop, val) => {
    let objDep = dep;
    objDep[prop] = val;
   // console.log('using dep', objDep)
     let ref = createDeploymentRef(objDep, 'C') 
      objDep.ref = ref;
      setDepRef(ref);
      setDep(objDep);
      if (['lat', 'lng'].indexOf(prop) > -1) {
        if (objDep.lat && objDep.lng && station) {
          let tdist = calcDistance(
            station.stationLat,
            station.stationLng,
            objDep.lat,
            objDep.lng
          );
          console.log('Distnace =', tdist);
          setDist(tdist * 1000); //distance is in km
        }
      }
     // console.log(objDep);
   
  };
  /*   const validateRef = async (val)=>{
      
    Client.getData('deployment', `ref='${val}'`).then(a => {
        setValidRef(a.data.length === 0);
         setTempDef(a.data[0])
         console.log('Temp deployment set to ',a.data[0])
    });
  } */
  const validateEq = async (evt, val1, val2) => {
    if (val1 === null || val1 === undefined) {
      setValidEq(false);
      setEquip({});
      return;
    }
    Client.getData(
      'equipment',
      `equipmentSerialNo='${val1.equipmentSerialNo}'`
    ).then(a => {
      setValidEq(a.data.length > 0);
      setEquip(a.data[0]);
    });
  };
  const validateEntry = async (evt, value) => {
    Client.getData('station', `stationName='${evt.target.value}'`).then(a => {
      if (a.data.length === 0) {
        setValidSite(false);
        return;
      }
      updateDep('stationName', evt.target.value);
      updateDep('stationid', a.data[0].stationId);
      updateDep('lat', a.data[0].stationLat);
      updateDep('lng', a.data[0].stationLng);
      setValidSite(true);

      setStation(a.data[0]);     
     

    });
  };
  useEffect(() => {
    Client.getData('equipment').then(result => {
      result.data.forEach(item => {
        item.label = `${item.equipmentName} - ${item.equipmentSerialNo}`;
      });
      setEqList(result.data);
    });

    Client.getData('crPosition').then(result => {
      result.data.forEach(item => (item.label = `${item.crPositionName}`));
      setPosList(result.data);
    });
  }, []);
  const createDeployment = () => {
    console.log('saving deployment')
    Client.saveData({ ...dep, tableName: 'deployment' }).then(newDep => {
      console.log(newDep)
      if (props.createCallBack)
        {
          console.log('getting newly created deployment record')
          Client.getData(
          'deployment',
          `deploymentid=${newDep.data.recordset[0].id}`
        ).then(resp => {
          console.log('got response, calling callback', resp)
          props.createCallBack(resp.data);
        });}
    });
  };
  const getStationHelperText = () => {
    return validSite ? '' : 'Station does not exist';
  };
  const getAdornment = arg => {
    let ico = arg ? (
      <FaCheck style={{ color: 'green' }} />
    ) : (
      <FaExclamationCircle style={{ color: 'orange' }} />
    );
    return <InputAdornment>{ico}</InputAdornment>;
  };
  return (
    <Paper
      style={{
        padding: 10,
        borderRadius: 10,
        maxWidth: 500,
        border: '2px solid darkorange',
        overflow: 'hidden'
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}
          >
            Create or Use Deployment for {props.title && props.title}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography>Create <Switch/> Use Existing</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>Reference</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            value={depRef}
            label="Reference" /* onChange={(evt)=>{updateDep('ref',evt.target.value); validateRef(evt.target.value)}} */
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>Station</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            style={{ color: 'darkgreen' }}
            type="text"
            error={!validSite}
            InputProps={{ startAdornment: getAdornment(validSite) }}
            helperText={getStationHelperText()}
            id="txt_site"
            onChange={validateEntry}
          />
        </Grid>

        {validSite && (
          <>
            <Grid item xs={12} md={6}>
              <Typography>Deployment Start Time</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                label="Start Time"
                InputProps={{
                  pattern: '(d{2})-(d{2})-(d{2}) (d{2}):(d{2}):(d{2})',
                  inputMode: 'date'
                }}
                onChange={evt => updateDep('startTime', evt.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Deployment End Time</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                label="End Time"
                onChange={evt => updateDep('endTime', evt.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Equipment Serial #</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={eqList}
                onChange={validateEq}
                InputProps={{ startAdornment: getAdornment(validEq) }}
                renderInput={params => (
                  <TextField
                    label="Serial Number"
                    helperText={!validEq ? 'Not a valid serial number' : ''}
                    error={!validEq}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Latitude</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                label="Lat"
                value={dep.lat}
                onChange={evt => updateDep('lat', evt.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Longitude</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                label="Lng"
                value={dep.lng}
                onChange={evt => updateDep('lng', evt.target.value)}
              />
            </Grid>
            <>
              {badDist < dist && (
                <Grid item xs={12}>
                  <Typography style={{ backgroundColor: 'pink' }}>
                    The coordinates specified put your deployment{' '}
                    {Math.round(dist)}m from site {station.stationName} are you
                    sure this is correct?{' '}
                  </Typography>
                </Grid>
              )}
            </>
            <Grid item xs={12} md={6}>
              <Typography>Position (only for continuous)</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={posList}
                renderInput={params => (
                  <TextField
                    type="text"
                    label="Recorder Position"
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button onClick={createDeployment}>Commit</Button>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default CreateDeployment;
