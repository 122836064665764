import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import DropDown from 'src/components/GenConnect/controls/DropDown';

export default function Latest() {
  return (
    <Paper style={{ padding: 5 }}>
      <Grid container>
        <Grid item xl={2} lrg={2} md={3} sm={6} xs={12}>
          <DropDown
            name="Metric"
            onChange={(e, b) => console.log(e, b)}
            table="metric"
            displayField="metricName"
          />
        </Grid>
        <Grid item xl={2} lrg={2} md={3} sm={6} xs={12}>
          <DropDown
            name="Strata"
            onChange={(e, b) => console.log(e, b)}
            table="strata"
            displayField="strataName"
          />
        </Grid>
        <Grid item xl={2} lrg={2} md={3} sm={6} xs={12}>
          <DropDown
            name="Metric Unit"
            onChange={(e, b) => console.log(e, b)}
            table="metricUnit"
            displayField="unitName"
          />
        </Grid>
        <Grid item xl={2} lrg={2} md={3} sm={6} xs={12} />
      </Grid>
    </Paper>
  );
}
