import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  CardContent,
  Card,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';

import CreateIcon from '@material-ui/icons/Create';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'none'
  },
  avatar: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    height: 48,
    width: 48
  }
}));

function SimpleMetric({ title, icon }) {
  const dashboardData = useSelector((state) => state.dashboardData);
  const [metricData, setMetricData] = useState();
  const classes = useStyles();

  useEffect(() => {
    if (dashboardData) {
      if (title === 'Open Compliance Concerns') {
        const openComplianceConcerns = dashboardData?.trackables?.filter(
          (trackable) => trackable?._status?.toLowerCase() !== 'closed'
            && trackable.condition?.toLowerCase() === 'concern'
        );
        setMetricData(openComplianceConcerns?.length);
      }

      if (title === 'Open Trackable Items') {
        const openTrackables = dashboardData?.trackables?.filter(
          (trackable) => trackable._status?.toLowerCase() !== 'closed'
            && trackable.follow_up_yes_no?.toLowerCase() === 'yes'
        );
        setMetricData(openTrackables?.length);
      }

      if (title === 'Total Trackable Items') {
        setMetricData(dashboardData?.trackables?.length);
      }

      if (title === 'Total Daily Reports') {
        setMetricData(dashboardData?.dailyReports?.length);
      }
    }
  }, [title, dashboardData]);

  return (
    <Card width="100%">
      <CardContent width="100%">
        <Grid container>
          <Grid item xs={1} xl={1} md={1}>
            <Avatar className={classes.avatar}>
              {icon === 'track' ? <ArtTrackIcon /> : icon || <CreateIcon />}
            </Avatar>
          </Grid>
          <Grid
            item
            xs={11}
            md={11}
            lg={11}
            style={{
              textAlign: 'center',
              verticalAlign: 'middle'
            }}
          >
            <Typography
              color="inherit"
              variant="h6"
              style={{ textTransform: 'uppercase' }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography
              color="inherit"
              variant="h4"
              className={classes.metricNumber}
              style={{ textAlign: 'center' }}
            >
              {metricData}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

SimpleMetric.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string
};

export default SimpleMetric;
