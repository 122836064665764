import { createSlice } from '@reduxjs/toolkit';

export const MapConfig = createSlice({
  name: 'mapConfig',
  initialState: {
    layers: [],
    mapData: [],
    selectedItem: null,
    selectedObs: null,
    actionType: 'mapIt',
    displayItems: {},
    position: { lat: 0, lng: 0 }
  },
  reducers: {
    setLayers: (state, action) => {
      state.layers = action.payload;
    },
    setLayerVis: (state, action) => {
      let layer = state.layers.find(a => a.file === action.payload.file);
      layer.visible =
        layer.visible === undefined || layer.visible === true ? false : true;
    },
    setMapData: (state, action) => {
      state.mapData = action.payload;
    },
    setDisplayItems: (state, action) => {
      state.displayItems = action.payload;
      state.actionType = 'mapIt';
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
      if (action.payload !== null) {
        state.position.lat = parseFloat(state.selectedItem._latitude);
        state.position.lng = parseFloat(state.selectedItem._longitude);
        state.actionType = 'mapSelect';
      }
    },
    setSelectedObs: (state, action) => {
      state.selectedObs = action.payload;
    }
  }
});

export const mapConfig = state => state.layers;

export const {
  setLayers,
  setLayerVis,
  setDisplayItems,
  setSelectedItem,
  setMapData,
  setSelectedObs
} = MapConfig.actions;

export default MapConfig.reducer;
