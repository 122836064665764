import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';
import { Paper, Typography } from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';

const StrataPie = ({
  data, displayField, title, style
}) => {
  const { settings } = useSettings();
  const getData = () => {
    const retData = [];
    data.map((item) => retData.push({
      id: item[displayField].toString(),
      label: item[displayField].toString(),
      value: item.itemCount
    }));
    return retData;
  };

  return (
    <Paper style={style}>
      <Typography variant="h5">{title}</Typography>
      <ResponsivePie
        data={getData()}
        margin={{
          top: 40,
          right: 80,
          bottom: 80,
          left: 80
        }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        isInteractive
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor={
          settings.theme === 'LIGHT' ? '#333333' : '#FFFFFF'
        }
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={settings.theme === 'LIGHT' ? '#333333' : '#FFFFFF'}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000'
                }
              }
            ]
          }
        ]}
      />
    </Paper>
  );
};

export default StrataPie;

StrataPie.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      itemCount: PropTypes.number,
      profileOnly: PropTypes.bool,
      tableName: PropTypes.string
    })
  ),
  displayField: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object
};
