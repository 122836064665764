import {
  renderWithToolTip,
  renderDate,
  renderLabel,
  renderLink
} from 'src/utils/mui-grid-helpers';
import 'src/assets/css/style-for-grid.css';

const getTitle = params => {
  if (!params.location_of_observation) {
    return params.trackable_id.split('-').slice(0, 2);
  }

  return `${params.trackable_id
    .split('-')
    .slice(0, 2)
    .join(' - ')} - ${params.location_of_observation.split(',')[0]}`;
};

const TrackableColumns = [
  {
    headerName: 'Identifier',
    field: 'trackable_id',
    minWidth: 160,
    flex: 1,
    align: 'left',
    headerAlign: 'center',
    valueGetter: params => getTitle(params.row),
    renderCell: params => renderWithToolTip(params)
  },
  {
    headerName: 'Initial Date',
    field: 'initial_date_of_trackable',
    flex: 0.5,
    width: 120,
    align: 'center',
    headerAlign: 'center',
    type: 'date',
    renderCell: params => renderDate(params)
  },
  {
    headerName: 'Last Update',
    field: '_updated_at',
    align: 'center',
    headerAlign: 'center',
    flex: 0.5,
    width: 120,
    sort: 'desc',
    type: 'date',
    valueGetter: params => params.row._updated_at,
    sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
    renderCell: params => renderDate(params)
  },
  {
    headerName: 'Location',
    field: 'location_of_observation',
    flex: 0.5,
    width: 160,
    align: 'left',
    headerAlign: 'center',
    renderCell: params => renderWithToolTip(params)
  },
  {
    headerName: 'Condition',
    field: 'condition',
    useLabels: true,
    flex: 0.5,
    width: 160,
    align: 'center',
    headerAlign: 'center',
    renderCell: arg => renderLabel(arg)
  },
  {
    headerName: 'Category',
    field: 'category',
    width: 160,
    align: 'left',
    headerAlign: 'center',
    renderCell: params => renderWithToolTip(params)
  },
  {
    headerName: 'Status',
    field: '_status',
    flex: 0.5,
    width: 160,
    align: 'center',
    headerAlign: 'center',
    renderCell: arg => renderLabel(arg)
  },
  {
    field: '_record_id',
    headerName: 'View',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    renderCell: arg => renderLink(`/app/Observation/${arg.value}`)
  }
];

export default TrackableColumns;
