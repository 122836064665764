import { Badge } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_ROOT } from 'src/config';
import useSettings from 'src/hooks/useSettings';

export default function AttachmentButton({ bindGuid, forcedUpdate, icon, iconSize }) {
  const { settings } = useSettings();
  const [files, setFiles] = useState(undefined);
  const Icon = icon ? icon : AttachFile;
  const getFiles = async () => {
    await axios
      .get(API_ROOT + 'File/FileList/' + settings.project + '/' + bindGuid)
      .then(result => {
        setFiles(result.data);
      });
  };

  useEffect(() => {
    getFiles();
  }, [forcedUpdate]);

  return (files && files.length) > 0 ? (
    <Badge badgeContent={files.length}>
      <Icon style={{ fontSize: iconSize ? iconSize: 16, color: 'blue' }}></Icon>
    </Badge>
  ) : (
    <Icon style={{ fontSize: iconSize ? iconSize: 16, color: 'grey' }}></Icon>
  );
}
