import axios from 'axios';
import API_BASE_URL from 'src/config';

const instance = axios.create({
  baseUrl: API_BASE_URL,
  timeout: 1000,
  headers: { 'X-Custom-Header': 'foobar' }
});

export default instance;
