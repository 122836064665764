import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Typography,
  Card,
  CardContent,
  Button
} from '@material-ui/core';
import moment from 'moment';
import { MAX_COMM_DEF_LENGTH } from 'src/config';
import SelectedComponent from 'src/components/SelectedComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    flexGrow: 5,
    display: 'flex',
    margin: theme.spacing(1)
  }
}));

export default function EachCommunication({ item, hideSourceLink }) {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();
  const recordID = localStorage.getItem('record');
  const record = JSON.parse(recordID);

  let dateFromNow = '';
  if (item.time && item.date) {
    const time = item.time.split(':');
    const hour = parseInt(time[0], 10);
    const minute = parseInt(time[1], 10);
    const date = moment(item.date).hour(hour).minute(minute);
    dateFromNow = date.fromNow();
  }

  return (
    <SelectedComponent
      selected={
        record?.field === 'Communications' && item?.metaData?.id === record?.id
      }
    >
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h6" color="textPrimary">
            {item?.field_person ? `${item.field_person} - ` : ''}
            {item.contacted ? item.contacted : 'Unknown'}
            {', '}
            {dateFromNow}
            {' '}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {item?.discussed
            && item?.discussed?.length > MAX_COMM_DEF_LENGTH ? (
              <>
                {expand ? (
                  item?.discussed
                ) : (
                  <>
                    {item?.discussed?.substring(0, MAX_COMM_DEF_LENGTH)}
                    {'...'}
                  </>
                )}
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setExpand(!expand)}
                >
                  {expand ? '[Read Less]' : '[Read More]'}
                </Button>
              </>
            ) : (
              item.discussed
            )}
            {hideSourceLink ? (
              <></>
            ) : (
              <Button
                size="small"
                color="primary"
                // eslint-disable-next-line no-return-assign
                onClick={() => (window.location.href = `/app/DailyReports/${item?.metaData
                  ?.id || item?._record_id}`)}
              >
                [Source Report]
              </Button>
            )}
          </Typography>
        </CardContent>
      </Card>
    </SelectedComponent>
  );
}

EachCommunication.propTypes = {
  item: PropTypes.object.isRequired,
  hideSourceLink: PropTypes.bool
};
