import { Checkbox, Grid, IconButton, Paper,Button } from '@material-ui/core';
import {
  Delete,
  Description,
  ImageRounded,
  PictureAsPdf
} from '@material-ui/icons';
import React from 'react';
 

export default function Attachment({ fileName, deleteRequest, downloadLink }) {
  
  const getIcon = () => {
    var re = /(?:\.([^.]+))?$/;
    var exten = re.exec(fileName);
    var icon = <Description />;
    //console.log(exten);
    if (exten[0]) {
      switch (exten[0].toString()) {
        case '.jpg':
          icon = <ImageRounded style={{ color: 'blue', fontSize: 18 }} />;
          break;
        case '.pdf':
          icon = <PictureAsPdf style={{ color: 'red', fontSize: 18 }} />;
          break;
        default:
          icon = <Description style={{ color: 'black', fontSize: 18 }} />;
      }
    }
    return icon;
  };
  return (
    <Paper>
      <Grid
        container
        style={{ alignItems: 'baseline', alignContent: 'flex-start' }}
      >
        <Grid item xs={2}>
          {' '}
          {getIcon()}
        </Grid>
        <Grid item xs={8} style={{ fontWeight: 500, fontSize: 12 }}>
          {' '}
          <Button onClick={()=>window.location.href=downloadLink}>
          {fileName}</Button>
        </Grid>
     
        <Grid item xs={2}>
          <IconButton size="small" onClick={() => deleteRequest(fileName)}>
            <Delete style={{ fontSize: 16 }} />
          </IconButton>{' '}
        </Grid>
      </Grid>
    </Paper>
  );
}
