import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import useSettings from 'src/hooks/useSettings';

const FileDelete = ({ recordID }) => {
  const { settings } = useSettings();

  const handleDelete = id => {
    alert(`Would delete - ${settings.project} - ${id}`);
  };

  return (
    <>
      <IconButton
        color="primary"
        size="small"
        onClick={() => handleDelete(recordID)}
      >
        <Delete style={{ fontSize: 18, color: 'red' }} meaning="Delete File" />
      </IconButton>
    </>
  );
};

export default FileDelete;

FileDelete.propTypes = {
  recordID: PropTypes.string
};
