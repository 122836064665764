import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography
} from '@material-ui/core';
import { ExitToApp, PieChart } from '@material-ui/icons';
import ReactCardFlip from 'react-card-flip';
import RSC from 'react-scrollbars-custom';
import Communication from 'src/views/communication/index';
import LoadingOverlay from 'react-loading-overlay';
import DashboardDataGridPro from './DashboardDataGridPro';
import PieDataChart from '../PieDataChart';

const DataCardFlipContainer = ({
  chartOptions,
  columnData,
  name,
  dataGridOptions,
  reduxData,
  hasRowId
}) => {
  const [showChart, setShowChart] = useState(false);
  const [downloading, setDownloading] = useState(false);

  return (
    <ReactCardFlip isFlipped={showChart}>
      {/* FRONT_COMPONENT */}
      <Card>
        <LoadingOverlay
          active={downloading}
          text={<Typography>Downloading Reports, please wait..</Typography>}
          spinner
        >
          {name.includes('Logs') ? (
            <></>
          ) : (
            <CardHeader
              action={
                <IconButton
                  aria-label="View Chart"
                  color="primary"
                  onClick={() => setShowChart(!showChart)}
                >
                  <PieChart titleAccess="View Chart" />
                </IconButton>
              }
              title={`${name.toUpperCase()} (${reduxData?.length || 0})`}
              titleTypographyProps={{ variant: 'h4' }}
            />
          )}
          <CardContent>
            {name === 'Recent Communications' ? (
              <RSC
                id="custo-Scroll"
                height={100}
                noScrollX
                style={{ display: 'flex', minHeight: 500 }}
              >
                <Communication coms={reduxData} hideSourceLink />
              </RSC>
            ) : (
              <DashboardDataGridPro
                columnData={columnData}
                dataGridOptions={dataGridOptions}
                name={name}
                data={reduxData}
                setDownloading={setDownloading}
                hasRowId
              />
            )}
          </CardContent>
        </LoadingOverlay>
      </Card>
      {/* BACK_COMPONENT */}
      <Card>
        <CardHeader
          title={`${name.toUpperCase()} (${reduxData?.length || 0})`}
          titleTypographyProps={{ variant: 'h4' }}
          action={
            <IconButton
              aria-label="Exit Chart"
              color="primary"
              onClick={() => setShowChart(!showChart)}
            >
              <ExitToApp titleAccess="Exit Chart" />
            </IconButton>
          }
        />
        <CardContent>
          {showChart ? (
            <PieDataChart data={reduxData} chartOptions={chartOptions} />
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </ReactCardFlip>
  );
};

export default DataCardFlipContainer;

DataCardFlipContainer.propTypes = {
  chartOptions: PropTypes.object,
  columnData: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  dataGridOptions: PropTypes.object,
  reduxData: PropTypes.arrayOf(PropTypes.object),
  hasRowId: PropTypes.bool,
};
