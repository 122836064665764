import { createSlice } from '@reduxjs/toolkit';

export const ReportManager = createSlice({
  name: 'reportManager',
  initialState: {
    activeReport: undefined,
    activeComs: undefined,
    activeCom: undefined,
    activeObservation: undefined,
    activePhotos: []
  },
  reducers: {
    //Assumes that data is sent in object format including a key property {key:'some key',data:[grid data]}
    setActiveReport: (state, action) => {
      state[action.payload.key] = action.payload.data;
      // state.reportData.activeReport = action.payload;
    },
    setActiveObservation: (state, action) => {
      // state.activeObservation = action.payload;
      state[action.payload.key] = action.payload.data;
    },
    setActiveObservationPhotos: (state, action) => {
      // state.activeObservation = action.payload;
      state.activePhotos = [...state.activePhotos, action.payload.data];
    }
  }
});

export const reportManager = state => state.reportData;

export const {
  setActiveReport,
  setActiveObservation,
  setActiveObservationPhotos
} = ReportManager.actions;
export default ReportManager.reducer;
